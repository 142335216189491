import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
    getOptionsConfig,
    getDefaultOptionsValues,
} from '@rawgraphs/rawgraphs-core'
import Profile from "../assets/img/Avatar.png";
import SampleList from "../data/sample-list";
import Menu from "../assets/img/menu.svg";
import Vector from "../assets/img/progress.svg";
import VectorConfirmed from "../assets/img/progressSuccess.svg";
import LogoutIcon from "../mui/LogoutIcon";
import ChatIconCollapsed from "../assets/img/collapsed-chat.svg"
import ExclamationIcon from "../assets/img/exclamation.svg";
import TickIcon from "../assets/img/check-circle.svg"
import data from "../assets/img/data.svg";
import link from "../assets/img/link.svg";
import DatalyzeLogo from "../assets/img/dataLyze-new.svg"

import EXlLogo from "../assets/img/exl_logo.png"
import HomeLogo from "../assets/img/Home-logo.svg"
import Lottie from "lottie-react";
import axios from "axios";
import LoadingLottie from "../assets/animations/B7nKG0EeXW.json"
import LoadingLottieChat from "../assets/animations/datalyze_loader_Black.json"
import { motion } from "framer-motion";
import 'react-tippy/dist/tippy.css';
import "./Gameplay.css"
import DataAnalysis from "./DataAnalysis";
import Avatar from './Avatar'
import CreditCard from "../data/subscriptionsData.json"
import Papa from 'papaparse';
import UploadCsv from "./Tutorials/UploadCsv";
import DataCleanup from "./Tutorials/DataCleanup";
import AnalysisModal from "./Tutorials/AnalysisModal"
import MissingDataFixingModal from "./Tutorials/MissingDataFixingModal";
import TreatmentModal from "./Tutorials/TreatmentModal";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useDispatch, useSelector } from "react-redux";
import ProblemList from "../data/problems";
import { logoutUser } from "../store/slices";
import {
    setCoinsCount
} from "../store/slices";
import { useGetProblemByIdQuery, useUpdateCoinsMutation } from "../store/services/datalyzeProblems";
import { useNavigate, useParams } from "react-router-dom";
import baseCharts from "./charts/charts";
import useDataLoader from "./hooks/useDataLoader"
import useSafeCustomCharts from './hooks/useSafeCustomCharts';
import DeleteDuplicateModal from "./Modals/DeleteDuplicateModal";
import CustomTypography from "../mui/customTypography";
import CoinsTooltipLg from "./CoinsTooltip/CoinsTooltipLg";
import CoinsTooltipSm from "./CoinsTooltip/CoinsTooltipSm";
import { BtnMain } from "../mui/customButton"
import { Box, Grid2, Button } from "@mui/material";
import Chatbox from "./Chat/Chatbox";
import AskGPT from "../services/AskGPT";
const tasks = [{ title: "Data Loading", status: "welcome" }, { title: "Data Cleaning", status: "cleanup" }, { title: "Data Analysis", status: "analyse" },];
import { useMediaQuery } from "react-responsive";
import AWS from 'aws-sdk';
import useSpeechControl from "../Hooks/useSpeech";

//helpers
import { findDuplicates, fillMissingCategoryValue, fillMissingNumericValue } from "../helpers/utility";

const Gameplay = () => {
    const fullURL = window.location.href;
    let isExl = fullURL?.includes("exl");
    const { problemId, customProblemSlug } = useParams();
    let isCustomProblem = problemId && problemId !== "custom" ? false : true;
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const dataMappingRef = useRef(null);
    const jwt = useSelector((state) => state?.user?.jwt);
    const currentProblem = useSelector((state) => state?.user?.value);
    const coinsCount = useSelector((state) => state?.user?.coinsCount);
    const userDetails = useSelector((state) => state?.user?.userDetails);
    const { data: problemData } =
        problemId !== "custom"
            ? useGetProblemByIdQuery({
                location: "app",
                token: jwt,
                id: problemId,
            })
            : { data: null };

    let problemDetails = ProblemList?.[currentProblem]
    const [task, setTask] = useState("welcome");
    const [showQuiz, setShowQuiz] = useState(false);
    const [isCheckedHideCleanup, setIsCheckedHideCleanup] = useState(false);
    const [tableData, setTableData] = useState(CreditCard);
    const [showCoinAnimation, setShowCoinAnimation] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [successIndex, setSuccessIndex] = useState(0)
    const speechRef = useRef(null);
    const containerRef = useRef();
    const [expand, setExpand] = useState(true);
    const [showDeleteToast, setShowDeleteToast] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [duplicateDataList, setDuplicateDataList] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const [userChatInput, setChatUserInput] = useState("")
    const [selectedAction, setSelectedAction] = useState("none");
    const [showActionCreator, setShowActionCreator] = useState(false);
    const [chatExpanded, setChatExpanded] = useState(true);
    const [isAwaitingGptResponse, setIsAwaitingGptResponse] = useState(false);
    //Tutorial animations
    const [showLinkCsvModal, setShowLinkCsvModal] = useState(false);
    const [showDataCleanupModal, setShowDataCleanuModal] = useState(false);
    const [selectedMissingValue, setSelectedMissingValue] = useState({})
    const [cleanupPerformed, setCleanupPerformed] = useState({
        duplicates: false,
        missing_data: false
    });
    const [quizAnswers, setQuizAnswers] = useState("");
    const [showNoDuplicateModal, setShowNoDuplicateModal] = useState(false);
    const [showNoEmptyModal, setShowNoEmptyModal] = useState(false);
    const [showAnalysis, setShowAnalysis] = useState(false);
    const [showMissingDataModal, setShowMissingDataModal] = useState(false);
    const [speechEnabled, setSpeechEnabled] = useState(true);
    const [showTreatmentModal, setShowTreatmentModal] = useState(false);
    const [updateCoins] = useUpdateCoinsMutation();
    const AWS_ACCESS_KEY = 'AKIA4OZIV247IDJBF7PU';
    const AWS_SECRET = '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'


    let [messages, setMessages] = useState([]);
    const { handleToggle } = useSpeechControl(messages, isSpeaking, setIsSpeaking, speechEnabled, setSpeechEnabled);
    const importMap = {
        "Letter Frequency By Language": require("../sample/Matrix Plot - Letter Frequency By Language.json"),
        "Multiset Bar chart - Happiness Index": require("../sample/Multiset Bar chart - Happiness Index.json"),
        "Netflix Original Series 2013/2017": require("../sample/Bar chart - Netflix Original Series.json"),
        "Violin Plot - Weather in New York 2012-2015": require("../sample/Violin Plot - Weather in New York 2012-2015.json"),
        "Top50Movies": require("../sample/Bubble Chart - TOP 50 Groossing Movies.json"),
        "Stacked Bar Chart - GDP Sector Composition": require("../sample/Stacked Bar Chart - GDP Sector Composition.json")
    };

    const polly = new AWS.Polly();

    AWS.config.update({
        region: 'ap-south-1',
        credentials: new AWS.Credentials(AWS_ACCESS_KEY, AWS_SECRET)
    });

    useEffect(() => {
        console.log("CHat expanded ?? : ", chatExpanded)
    }, [chatExpanded])

    //Charts
    const [showChartSelector, setShowChartSelector] = useState(false);

    const [
        customCharts,
    ] = useSafeCustomCharts()
    const charts = useMemo(() => baseCharts.concat(customCharts), [customCharts]);
    const dataLoader = useDataLoader()
    const {
        setUserInput,
        data: dataSet,
    } = dataLoader
    const [visualOptions, setVisualOptions] = useState(() => {
        const options = getOptionsConfig(charts[0]?.visualOptions)
        return getDefaultOptionsValues(options)
    })
    const [rawViz, setRawViz] = useState(null)
    console.log(rawViz);
    const [currentChart, setCurrentChart] = useState(charts?.[0]);
    const isMobile = useMediaQuery({ query: "(max-width:768px)" });

    useEffect(() => {
        if (customProblemSlug) {
            let customDatasetObj = SampleList?.filter((x) => x?.sourceName === customProblemSlug)?.[0];
            setCurrentChart(charts?.[customDatasetObj?.defaultBarIndex])
        } else {
            setCurrentChart(charts?.[problemData?.data?.defaultBarIndex]);
        }
    }, [problemData?.data?.defaultBarIndex, customProblemSlug])


    useEffect(() => {
        if (problemData?.data?.messages?.welcomeMessage && chatExpanded && !isCustomProblem) {
            setMessages([...messages, {
                text: `Hey there!, ${problemData?.data?.messages?.welcomeMessage}`,
                type: "Sensei"
            }]);
        } else if (isCustomProblem) {
            setMessages([...messages, {
                text: `Hey there! Select a sample dataset to work on.`,
                type: "Sensei"
            }]);
        }

    }, [chatExpanded, problemData?.data?.messages?.welcomeMessage]);

    useEffect(() => {
        if (chatExpanded && containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [chatExpanded, messages]);


    const playCoinsSound = () => {
        const sound = () => {
            const audio = new Audio("/collectcoin.wav");
            audio.play();
        };
        setTimeout(() => {
            sound();
        }, 500);

    }

    const handleSuccessfullyCreditedCoins = (returnedCoinsCount) => {
        if (returnedCoinsCount && returnedCoinsCount > coinsCount) {
            playCoinsSound()
            setShowCoinAnimation(true);
            dispatch(setCoinsCount({
                coinsCount: returnedCoinsCount
            }))
            setTimeout(() => {
                setShowCoinAnimation(false)
            }, 2000);
        }
    }

    useEffect(() => {
        if (showQuiz && !isCustomProblem) {
            setMessages([...messages, {
                text: "Let's have a quick test to see if you have cracked the problem.",
                type: "Sensei"
            }])
        }
    }, [showQuiz])

    const clearLocalMapping = useCallback(() => {
        if (dataMappingRef.current) {
            dataMappingRef.current.clearLocalMapping()
        }
    }, [])


    const handleChartChange = useCallback(
        (nextChart) => {
            // setMapping({})
            clearLocalMapping()
            setCurrentChart(nextChart)
            const options = getOptionsConfig(nextChart?.visualOptions)
            setVisualOptions(getDefaultOptionsValues(options))
        },
        [clearLocalMapping]
    )

    const handleAskGPT = async (userChatInput) => {
        setIsAwaitingGptResponse(true);
        try {
            const result = await AskGPT(problemData?.data?.title, userChatInput, tableData);
            setMessages((prevMessages) => [...prevMessages, {
                text: result?.data?.answer,
                type: "Sensei"
            }]); // Append the response
            setIsAwaitingGptResponse(false);
        } catch (err) {
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    text: `Consider experimenting with different questions.`,
                    type: "Sensei"
                }
            ]); // Append error message
            setIsAwaitingGptResponse(false);
        }
    };

    useEffect(() => {
        if (speechEnabled && messages?.length > 0) {
            const currentMessage = messages[messages.length - 1];
            if (currentMessage && currentMessage?.type === "Sensei") {
                if (speechRef.current && !speechRef.current.paused) {
                    speechRef.current.pause();
                    speechRef.current.currentTime = 0; // Reset the playback
                }
                // Use AWS Polly to generate the speech
                const params = {
                    OutputFormat: 'mp3',
                    Text: currentMessage?.text,
                    VoiceId: 'Raveena',
                };
                polly.synthesizeSpeech(params, (err, data) => {
                    if (err) {
                        console.error("Error with Polly: ", err);
                        return;
                    }
                    const audio = new Audio();
                    audio.src = URL.createObjectURL(new Blob([data.AudioStream], { type: 'audio/mp3' }));
                    audio.onplay = () => setIsSpeaking(true);
                    audio.onended = () => setIsSpeaking(false);

                    audio.play();
                    speechRef.current = audio;
                });
            }
        }


        return () => {
            if (speechRef.current) {
                speechRef.current.pause();  // Pause the audio if it's playing
                speechRef.current.currentTime = 0;  // Reset to the start of the audio
                setIsSpeaking(false);  // Update state to indicate speech is not playing
            }
        };

    }, [messages?.length, speechEnabled]);


    const getUniqueObjects = () => {
        const uniqueArray = [];
        const seen = new Set();
        tableData?.forEach((obj) => {
            const objString = JSON.stringify(obj);
            if (!seen.has(objString)) {
                seen.add(objString);
                uniqueArray.push(obj);
            }
        });

        return uniqueArray;
    };

    const handleCleanupData = (type) => {
        if (type === "duplicates") {
            if (cleanupPerformed?.duplicates) {
                setMessages([...messages, {
                    text: `All clear—no duplicates here.This dataset is already on the right track!`,
                    type: "Sensei"
                }])
                setShowNoDuplicateModal(true);
                setTimeout(() => {
                    setShowNoDuplicateModal(false);
                }, 3000)
                setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                    "duplicates": true
                }))
            } else {
                const duplicateData = findDuplicates(tableData);
                if (duplicateData?.length > 0) {
                    setShowDeleteToast(true)
                    setDuplicateDataList(duplicateData);
                    setMessages([...messages, {
                        text: `Aha! Found ${duplicateData?.length} duplicates. Good catch!`,
                        type: "Sensei"
                    }])
                } else {
                    setShowNoDuplicateModal(true);
                    setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                        "duplicates": true
                    }))
                    setMessages([...messages, {
                        text: `All clear—no duplicates here.This dataset is already on the right track!`,
                        type: "Sensei"
                    }])
                    setTimeout(() => {
                        setShowNoDuplicateModal(false);
                    }, 3000)
                }
            }
        } else if (type === "missing_data") {
            if (cleanupPerformed?.missing_data) {
                setMessages([...messages, {
                    text: "Looks like the data is complete.",
                    type: "Sensei"
                }])
                setShowNoEmptyModal(true)
                setTimeout(() => {
                    setShowNoEmptyModal(false);
                }, 3000)
                setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                    "missing_data": true
                }))
                return;
            }

            setShowDeleteToast(false);
            const incompleteData = tableData?.filter((x) => {
                return Object.values(x).some(value => value === null || value === undefined || value === "");
            });

            if (!incompleteData?.length) {
                setMessages([...messages, {
                    text: "Looks like the data is complete.",
                    type: "Sensei"
                }])
                setShowNoEmptyModal(true)
                setTimeout(() => {
                    setShowNoEmptyModal(false);
                }, 3000)
                setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                    "missing_data": true
                }))
            } else {
                setDuplicateDataList(incompleteData);
                setMessages([...messages, {
                    text: `Oh no, looks like some values are missing! Don’t worry—we’ll fix them in no time.`,
                    type: "Sensei"
                }])
                if (localStorage.getItem("dont-show-missing-value") === "true") {
                    setShowMissingDataModal(true);
                    return;
                } else {
                    setTimeout(() => {
                        setShowTreatmentModal(true);
                    }, 1000);
                }

            }
        } else {
            console.log("reset")
        }
    }


    const handleFixingMissingData = async () => {
        setMessages([...messages, {
            text: "Impressive! You’ve patched up all the gaps. No blanks holding us back now.",
            type: "Sensei"
        }])
        let finalFixedList = tableData?.slice();
        const CompleteDataSet = tableData?.filter((x) => {
            return Object.values(x).every(value => value === 0 || Boolean(value))
        });

        finalFixedList = duplicateDataList?.map((set) => {
            let finalObj = Object.assign({}, set);
            let hasMoreThanOneFieldMissing = Object.keys(finalObj)?.filter((x) => !finalObj?.[x])?.length > 1 ? true : false;
            if (hasMoreThanOneFieldMissing) {
                let listOfAllTheMissingFields = Object.keys(finalObj)?.filter((x) => !finalObj?.[x]);
                listOfAllTheMissingFields.forEach((missingField) => {
                    let dataType = dataSet?.dataTypes?.[missingField];
                    finalObj = Object.assign({}, finalObj, {
                        [missingField]: dataType === "string" ? fillMissingCategoryValue(missingField) : fillMissingNumericValue(missingField, selectedMissingValue, tableData)
                    })
                })
            } else {
                Object.keys(set)?.forEach((key) => {
                    if (!set?.[key]) {
                        let dataType = dataSet?.dataTypes?.[key];
                        if (dataType === "string") {
                            finalObj = Object.assign({}, set, {
                                [key]: fillMissingCategoryValue(key)
                            })
                        } else {
                            finalObj = Object.assign({}, set, {
                                [key]: Number(fillMissingNumericValue(key, selectedMissingValue, tableData))
                            })
                        }
                    }
                })
            }

            return finalObj;
        })

        let allFieldsToBeDeleted = [];
        Object.keys(selectedMissingValue)?.forEach((key) => {
            if (selectedMissingValue?.[key] === "delete") {
                allFieldsToBeDeleted.push(key);
            }
        });

        if (allFieldsToBeDeleted?.length > 0) {
            allFieldsToBeDeleted?.forEach((keyToBeDeleted) => {
                finalFixedList = finalFixedList?.filter((x) => x?.[keyToBeDeleted])
            })
        }

        const csvData = Papa.unparse([...(finalFixedList?.length > 0 ? finalFixedList : []), ...(CompleteDataSet?.length > 0 ? CompleteDataSet : [])]);
        setUserInput(csvData)
        setTableData([...(finalFixedList?.length > 0 ? finalFixedList : []), ...(CompleteDataSet?.length > 0 ? CompleteDataSet : [])]);
        setDuplicateDataList([]);
        setCleanupPerformed(Object.assign({}, cleanupPerformed, {
            "missing_data": true
        }))


        const { data } = await updateCoins({
            body: {
                "problemId": problemId,
                "action": "data-loading",
            },
            token: jwt
        });
        handleSuccessfullyCreditedCoins(data?.data?.totalScore);
        setShowMissingDataModal(false)
    }


    const handleGoBackToProgress = (status) => {
        setTask(status)
        setShowQuiz(false);
    }

    const fetchDataFromS3 = async (url) => {
        try {
            const response = await axios.get(url);
            const data = response.data;
            return data;
        } catch (error) {
            console.error('Error fetching or parsing data: here', error);
            return null;
        }
    };

    const handleLinkData = async () => {
        setTask("linkingData");
        setExpand(false);
        try {
            const response = await fetchDataFromS3(problemData?.data?.dataSource);
            const csvData = Papa.unparse(response);
            setUserInput(csvData);
            setTableData(response);
            setTask("cleanup")
            setMessages([...messages, {
                text: "Ah, there it is! The data has arrived safely. Let’s have a quick look to see what we’re working with.",
                type: "Sensei"
            }])

            const { data } = await updateCoins({
                body: {
                    "problemId": problemId,
                    "action": "data-loading",
                },
                token: jwt
            });


            handleSuccessfullyCreditedCoins(data?.data?.totalScore);
            setTimeout(() => {
                setShowCoinAnimation(false)
                setSuccessIndex(1)
            }, 2000)

        } catch (error) {
            console.error("Error loading JSON file:", error);
        }
    }

    const containerClass = task === "linkingData" ? 'center-content' : 'default-content';

    const handleProceedActionCreator = async () => {
        if (cleanupPerformed?.duplicates && cleanupPerformed?.missing_data) {
            setShowChartSelector(true);
            setTask("analyse");
            setSuccessIndex(2);

            const { data } = await updateCoins({
                body: {
                    "problemId": problemId,
                    "action": "data-cleaning",
                },
                token: jwt
            });
            handleSuccessfullyCreditedCoins(data?.data?.totalScore);
            if (problemData?.data?.messages?.chartMessage1 && problemData?.data?.messages?.chartMessage2) {
                setMessages(prevMessages => [
                    ...prevMessages,
                    {
                        text: `${problemData?.data?.messages?.chartMessage1}  ${problemData?.data?.messages?.chartMessage2}`,
                        type: "Sensei"
                    }
                ]);
            }
        } else {
            if (localStorage.getItem('dont-show-cleanup') === "true") {
                setShowActionCreator(true);
                setShowDataCleanuModal(false);
                setMessages([...messages, {
                    text: "Hmm, let’s see if we’ve got any pesky duplicates lurking around.",
                    type: "Sensei"
                }])
                if (cleanupPerformed?.duplicates && cleanupPerformed?.missing_data) {
                    setShowChartSelector(true);
                    setTask("analyse");
                    setSuccessIndex(2);
                } else if (!cleanupPerformed?.duplicates) {
                    setSelectedAction("duplicates")
                    handleCleanupData("duplicates")
                } else if (!cleanupPerformed?.missing_data) {
                    setSelectedAction("missing_data")
                    handleCleanupData("missing_data")

                }

                return;
            } else {
                setShowDataCleanuModal(true);
            }
        }
    }
    const handleProceedCompleteDataAnalysis = async (shouldCreditCoins) => {
        if (task === "analyse" && !showQuiz) {
            if (!isCustomProblem) {
                setShowQuiz(true);
                setSuccessIndex(3);
                if (shouldCreditCoins) {
                    const { data } = await updateCoins({
                        body: {
                            "problemId": problemId,
                            "action": "data-analysis",
                        },
                        token: jwt
                    });
                    handleSuccessfullyCreditedCoins(data?.data?.totalScore);
                }
            } else {
                return;
            }
        }
    }

    const handleGoBackToAnalysis = () => {
        setShowQuiz(false);
    }

    return (
        <div
            className="wrapper"
        >
            <div style={{ display: "flex", flexDirection: "column" }}>
                <motion.div
                    className="home-container"
                    style={{ justifyContent: expand ? "space-between" : "center" }}
                    animate={{
                        width: expand ? isMobile ? "200px" : "303px" : "80px",
                        opacity: expand ? 1 : 0.8,
                    }}
                    transition={{
                        type: "spring",
                        stiffness: 150, // Adjust for spring speed
                        damping: 14,    // Adjust for smoothness
                    }}
                >
                    {expand ?
                        <>
                            {isExl ? <img className={"exl-img"} style={{ cursor: 'pointer' }} src={EXlLogo} onClick={() => {
                                navigate(`/gamelist`)
                            }} /> : <img className={"datalyze-img"} style={{ cursor: 'pointer' }} src={DatalyzeLogo} onClick={() => {
                                navigate(`/gamelist`)
                            }} />}

                            <img className="burger-menu-expanded" style={{ cursor: 'pointer' }} src={Menu} onClick={() => {
                                setExpand((prev) => !prev)
                            }} />
                        </>
                        :
                        <img className="burger-menu-expanded" style={{ cursor: 'pointer' }} src={HomeLogo} onClick={() => {
                            navigate(`/gamelist`)
                        }} />
                    }

                </motion.div>
                <motion.div
                    className="progress-holder"
                    animate={{
                        width: expand ? isMobile ? "200px" : "303px" : "80px",
                        opacity: expand ? 1 : 0.8,
                    }}
                    transition={{
                        type: "spring",
                        stiffness: 150,
                        damping: 14,
                    }}
                >
                    <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, ease: "easeOut" }}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <motion.div
                            initial={{ opacity: 0, x: -30 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.2, duration: 0.5 }}
                            style={{
                                display: "flex",
                                flexDirection: expand ? "row" : "column",
                                alignItems: "center",
                            }}
                        >
                            {!expand &&
                                <img
                                    src={Menu}
                                    onClick={() => {
                                        setExpand((prev) => !prev)
                                    }}
                                    className="burger-menu-collapsed"
                                    style={{ marginBottom: "25px", cursor: "pointer" }}
                                />
                            }
                            <img
                                src={Profile}
                                style={{ width: "48px", height: "48px", ...(expand && { marginRight: isMobile ? "0px" : "20px" }) }}
                            />
                            {expand &&
                                <motion.p
                                    initial={{ opacity: 0, x: -20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ delay: 0.4, duration: 0.5 }}
                                    style={{ fontSize: "16px", fontWeight: 600, color: "#fff" }}
                                >
                                    {userDetails?.name}
                                </motion.p>}
                        </motion.div>
                    </motion.div>

                    {
                        expand ? <div className="progress-tooltip" style={{ marginTop: "20px" }}>
                            <p
                                className="task-text"
                            >
                                TASKS
                            </p>

                            {tasks.map((item, index) => (
                                <motion.div
                                    key={task}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{
                                        delay: index * 0.2,
                                        duration: 0.5,
                                        ease: "easeOut",
                                    }}
                                    className="taskDetailItemExpanded"
                                >
                                    <img src={index + 1 <= successIndex ? VectorConfirmed : Vector} style={{ width: "18px", height: "18px", marginRight: "10px" }} />
                                    <p style={{ fontSize: "12px", fontWeight: 600, color: "#E8E9E9", letterSpacing: "0.15px" }}>
                                        {item?.title}
                                    </p>
                                </motion.div>
                            ))}

                        </div>
                            : <div className="progress-tooltip" style={{ marginTop: "20px", background: "none" }}>
                                {tasks.map((item, index) => (
                                    <div
                                        className="taskDetailItemUnexpanded"
                                        key={index} // Use index as key
                                        id={`tooltip-${index}`} // Unique ID for each tooltip
                                        onClick={() => {
                                            if (index + 1 <= successIndex) {
                                                handleGoBackToProgress(item?.status)
                                            }
                                        }}
                                    >
                                        <img
                                            src={index + 1 <= successIndex ? VectorConfirmed : Vector}
                                            style={{ width: "18px", height: "18px", marginRight: "10px" }}
                                            alt={`Step ${index + 1}`}
                                        />
                                        <Tooltip
                                            anchorId={`tooltip-${index}`} // Match the ID of this element
                                            content={item?.title || `Task ${index + 1}`} // Tooltip content
                                            place="top"
                                            offset={10}
                                            className="react-tooltip"
                                            style={{ fontSize: "12px", zIndex: 999 }}
                                        />
                                    </div>
                                ))}
                            </div>}

                    <div className="logout-btn-holder">
                        {expand ? <Button
                            className="logout-btn-styles"
                            variant="contained"
                            onClick={() => {
                                dispatch(logoutUser());
                                navigate(`/login`);
                            }}
                        >
                            Logout
                            <LogoutIcon />
                        </Button> :
                            <>
                                <div
                                    onClick={() => {
                                        dispatch(logoutUser());
                                        navigate(`/login`);
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <LogoutIcon
                                        height={"20"}
                                        width={"20"}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </motion.div >
            </div>

            <Box sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column", md: "row" },
                width: "100%",
                gap: 2,
            }}>
                <CoinsTooltipSm
                    showCoinAnimation={showCoinAnimation}
                    coinsCount={coinsCount}
                />
                <Grid2
                    item
                    className={`data-container-${containerClass}`}
                    sx={{ position: "relative" }}
                >
                    <motion.div
                        animate={{
                            width: "100%",
                        }}
                        transition={{
                            duration: 0.4, // Adjust for smoothness pranay
                            ease: [0.25, 0.8, 0.25, 1]
                        }}
                    >

                        {task === "welcome" &&
                            <>

                                <Grid2 sx={{ display: "flex", flexDirection: "column", height: "300px", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                    <motion.h1
                                        className="problemTitleText"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.6, ease: "easeOut" }}
                                    >

                                        {isCustomProblem ? "Choose one of the sample datasets" : problemDetails?.welcomeTitle}
                                    </motion.h1>
                                    <motion.h1
                                        className="problemSubTitleText"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.6, ease: "easeOut", delay: 0.3 }}
                                    >
                                        {problemData?.data?.title}
                                    </motion.h1>

                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.6, ease: "easeOut", delay: 0.6 }}
                                    >
                                        <CustomTypography
                                            sx={{
                                                fontSize: { md: "1rem" },
                                                fontWeight: 500,
                                                lineHeight: "24px",
                                                color: "#8E8C91",
                                                marginTop: "8px",
                                                textAlign: "center",
                                            }}>
                                            {problemData?.data?.description}
                                        </CustomTypography>
                                    </motion.div>
                                </Grid2>

                                {!isCustomProblem ? <div className="data-img">
                                    <img src={data} style={{ width: "100%", height: "360px", padding: "1rem" }}></img>
                                </div>
                                    :
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        flexWrap: "wrap",
                                    }}>
                                        {SampleList?.map((x, index) => {
                                            return (
                                                <div
                                                    className="sampleProblemCard"
                                                    onClick={async () => {

                                                        if (isExl || (index === 0 || index === 1)) {
                                                            setTask("linkingData");
                                                            setExpand(false);
                                                            const fileName = `${x?.url}`; // Get the file name dynamically
                                                            const data = importMap[fileName] || null; // use the mapped file or null
                                                            setUserInput(Papa.unparse(data));
                                                            setTableData(data);
                                                            setTask("cleanup")
                                                            setMessages([...messages, {
                                                                text: "Ah, there it is! The data has arrived safely. Let’s have a quick look to see what we’re working with.",
                                                                type: "Sensei"
                                                            }])
                                                            navigate(`/gamified-data-analytics/custom/${x?.sourceName}`)
                                                        }
                                                    }}
                                                >
                                                    {(index !== 0 && index !== 1 && !isExl) && <div className="sampledataset-subscribe-overflow">
                                                        Subscribe to Unlock 🔒
                                                    </div>}
                                                    <p style={{ fontSize: "16px", marginBottom: "5px" }}>{x?.name}</p>
                                                    <p style={{ fontSize: "12px" }}>{`(${x?.category})`}</p>
                                                </div>)
                                        })}
                                    </div>
                                }
                            </>
                        }

                        {task === "linkingData" &&
                            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }}>
                                <Lottie
                                    animationData={LoadingLottie}
                                    loop={true}
                                    className="loaderStyles"
                                />
                                <h1
                                    className="linkingText"
                                >
                                    We are linking your data
                                </h1>
                                <p
                                    className="linkingSubText"
                                >
                                    This may take some time
                                </p>


                            </div>}
                        {(task === "analyse" || task === "cleanup" || task === "proceed" || task === "summary") &&
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: task === "linked" ? "center" : "", padding: "20px" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "5px", marginTop: task === "linked" ? "0px" : "10px", width: "100%" }}>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                        {!showQuiz && (<p className="problem-title-text">
                                            {isCustomProblem ? SampleList.find((sample) => sample.sourceName === customProblemSlug)?.name || "Custom Problem" :
                                                problemData?.data?.title
                                            }
                                        </p>
                                        )}
                                        <motion.h2
                                            className="problem-subtitle-text"
                                            style={{
                                                fontSize: (task === "linked" || task === "cleanup" || task === "proceed") ? "28px" : "24px",
                                            }}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ delay: 0.5, duration: 1 }}
                                        >
                                            {task === "linked" || task === "cleanup" ? "Data Cleaning" : !showQuiz && task === "analyse" && "Choose a Chart"}
                                        </motion.h2>
                                    </div>

                                    {task === "analyse" && !showQuiz && !showChartSelector && <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div
                                            className="back-btn"
                                            onClick={() => {
                                                setShowChartSelector(true);
                                            }}
                                        >
                                            {"<"}
                                        </div>

                                    </div>}
                                </div>
                                <Grid2 sx={{ display: "flex", flexDirection: "column", width: "100%", height: "auto", }}>
                                    <MissingDataFixingModal
                                        missingValueList={duplicateDataList}
                                        allData={tableData}
                                        selectedMissingValue={selectedMissingValue}
                                        setSelectedMissingValue={setSelectedMissingValue}
                                        onSubmit={handleFixingMissingData}
                                        open={showMissingDataModal}
                                        dataSet={dataSet}
                                    />

                                    <DataAnalysis
                                        task={task}
                                        setTask={setTask}
                                        setShowQuiz={setShowQuiz}
                                        tableData={duplicateDataList?.length > 0 ? duplicateDataList : tableData}
                                        setTableData={setTableData}
                                        showDeleteToast={showDeleteToast}
                                        selectedAction={selectedAction}
                                        setSelectedAction={setSelectedAction}
                                        handleCleanupData={handleCleanupData}
                                        showQuiz={showQuiz}
                                        quizAnswers={quizAnswers}
                                        setQuizAnswers={setQuizAnswers}
                                        showChartSelector={showChartSelector}
                                        setShowChartSelector={setShowChartSelector}
                                        setShowAnalysis={setShowAnalysis}
                                        charts={charts}
                                        currentChart={currentChart}
                                        dataMappingRef={dataMappingRef}
                                        dataSet={dataSet}
                                        visualOptions={visualOptions}
                                        setVisualOptions={setVisualOptions}
                                        setRawViz={setRawViz}
                                        handleChartChange={handleChartChange}
                                        showActionCreator={showActionCreator}
                                        setMessages={setMessages}
                                        messages={messages}
                                        chatExpanded={chatExpanded}
                                        assessments={problemData?.data?.assessments}
                                        problemData={problemData?.data}
                                        isCustomProblem={isCustomProblem}
                                        handleProceedActionCreator={handleProceedActionCreator}
                                        handleProceedCompleteDataAnalysis={handleProceedCompleteDataAnalysis}
                                        handleGoBackToAnalysis={handleGoBackToAnalysis}
                                        handleSuccessfullyCreditedCoins={handleSuccessfullyCreditedCoins}
                                    />
                                </Grid2>

                            </div>
                        }
                        <div style={{ marginTop: "20px", display: "flex", justifyContent: "end", position: "absolute", width: "100%", bottom: -40 }}>
                            {messages?.length <= 1 ?
                                <div
                                    className="footer-proceed-btn-container"
                                    style={{
                                        position: "absolute",
                                        bottom: 50,
                                        left: 0,
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                    }}>
                                        {!isCustomProblem && <motion.div
                                            className="footer-proceed-btn"
                                            animate={{
                                                background: [
                                                    "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                                                    "linear-gradient(91deg, #00E5FF 7.38%, #00FF85 96.85%)",
                                                    "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                                                ],
                                            }}
                                            transition={{
                                                duration: 2, // Total time for one cycle
                                                ease: "linear",
                                                repeat: Infinity, // Loop the animation infinitely
                                            }}
                                            whileHover={{
                                                scale: 1.1,
                                                transition: { duration: 0.3 },
                                            }}
                                            onClick={() => {
                                                setMessages([
                                                    ...messages,
                                                    {
                                                        text: "First, load the dataset.",
                                                        type: "Sensei"
                                                    }
                                                ]);
                                                if (localStorage.getItem('dont-show-upload') === "true") {
                                                    return
                                                } else {
                                                    setTimeout(() => {
                                                        setShowLinkCsvModal(true);
                                                    }, 500)
                                                }
                                            }}
                                        >
                                            Proceed
                                        </motion.div>}

                                    </div>
                                </div> : task === "welcome" ?
                                    <div
                                        className="action-btn-wrapper">
                                        {!isCustomProblem &&
                                            <>
                                                <motion.div
                                                    whileHover={{
                                                        scale: 1.05,
                                                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                                                        transition: { duration: 0.3 },
                                                    }}
                                                >
                                                    <BtnMain onClick={() => {
                                                        handleLinkData()
                                                    }}> Link Data
                                                        <img src={link} style={{ marginLeft: "8px" }} />
                                                    </BtnMain>
                                                </motion.div>
                                            </>
                                        }
                                    </div> : null}
                        </div>
                    </motion.div >
                </Grid2>

                <div
                    className={`spring-effect ${chatExpanded ? "expanded" : "collapsed"}`}
                    style={{ width: chatExpanded ? isMobile ? "100%" : "45%" : "80px", display: 'flex', flexDirection: "column", marginLeft: "15px", }}>
                    {!chatExpanded ?
                        <div style={{ display: "flex", flexDirection: "column", height: "90vh", justifyContent: "center" }}>
                            <div className='chat-minimized-view' onClick={() => {
                                setChatExpanded((prev) => !prev)
                            }}>
                                {isAwaitingGptResponse ? <Lottie
                                    animationData={LoadingLottieChat}
                                    loop={true}
                                    className="chatLoaderStyles"
                                /> :
                                    <img src={ChatIconCollapsed} alt="ChatIconCollapsed" />
                                }
                            </div>
                        </div>
                        :
                        <>
                            <CoinsTooltipLg
                                showCoinAnimation={showCoinAnimation}
                                chatExpanded={chatExpanded}
                                coinsCount={coinsCount}
                            />

                            <Chatbox
                                containerRef={containerRef}
                                messages={messages}
                                showLinkCsvModal={showLinkCsvModal}
                                showDataCleanupModal={showDataCleanupModal}
                                showAnalysis={showAnalysis}
                                userChatInput={userChatInput}
                                onFocusHandler={() => {
                                    if (showTooltip) {
                                        setShowTooltip(false);
                                    }
                                }}
                                onChangeHandler={(text) => {
                                    setChatUserInput(text)
                                }}
                                onKeyDownHandler={(e) => {
                                    if (e.key === "Enter" && userChatInput.trim()) {
                                        setMessages((prevMessages) => [...prevMessages, {
                                            text: userChatInput,
                                            type: "You"
                                        }]); // Immediately add the input to the messages
                                        setChatUserInput(""); // Clear the input field
                                        handleAskGPT(userChatInput);
                                    }
                                }}
                                handleOnClick={() => {
                                    setMessages((prevMessages) => [...prevMessages, {
                                        text: userChatInput,
                                        type: "You"
                                    }]); // Immediately add the input to the messages
                                    setChatUserInput(""); // Clear the input field
                                    handleAskGPT(userChatInput);
                                }}
                                task={task}
                                chatExpanded={chatExpanded}
                                setChatExpanded={setChatExpanded}
                                handleToggleChat={() => {
                                    setChatExpanded((prev) => !prev)
                                }}
                                isAwaitingGptResponse={isAwaitingGptResponse}
                            />
                        </>
                    }

                    <div className="avatar-tooltip" onClick={handleToggle}>
                        <Avatar isSpeaking={isSpeaking} />
                    </div>

                </div>
            </Box >
            {
                showNoDuplicateModal && <motion.div
                    className="no-duplicate-dataset-toast"
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 50, damping: 15 }}
                >
                    <img src={ExclamationIcon} style={{ marginRight: "5px" }} />
                    No Duplicates found

                </motion.div>
            }
            {
                showNoEmptyModal && <motion.div
                    className="no-duplicate-dataset-toast"
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 50, damping: 15 }}
                >
                    <img src={ExclamationIcon} style={{ marginRight: "5px" }} />
                    No Missing Values found

                </motion.div>
            }

            <DeleteDuplicateModal
                onClickHandler={() => {
                    setShowDeleteToast(false);
                    const uniqueData = getUniqueObjects();
                    setTableData(uniqueData);
                    setShowSuccessToast(true)
                    setTimeout(() => {
                        setShowSuccessToast(false)
                    }, 2000)
                    setDuplicateDataList([])
                    setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                        "duplicates": true,
                    }))
                    setMessages([...messages, {
                        text: "Great work! You just trimmed the excess. The data looks neater now.",
                        type: "Sensei"
                    }])
                }}
                showDeleteToast={showDeleteToast}
            />
            {
                showSuccessToast && task === "cleanup" && <motion.div
                    className="success-dataset-toast"
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 50, damping: 15 }}
                >
                    Duplicate data Successfully deleted.
                    <img src={TickIcon} />
                </motion.div>
            }

            <UploadCsv
                setShowLinkCsvModal={setShowLinkCsvModal}
                open={showLinkCsvModal}
                setMessages={setMessages}
                messages={messages}
            />

            <DataCleanup
                open={showDataCleanupModal}
                handleClose={() => {
                    setShowActionCreator(true);
                    setShowDataCleanuModal(false);
                    setMessages([...messages, {
                        text: "Hmm, let’s see if we’ve got any pesky duplicates lurking around.",
                        type: "Sensei"
                    }])
                    if (cleanupPerformed?.duplicates && cleanupPerformed?.missing_data) {
                        setShowChartSelector(true);
                        setTask("analyse");
                        setSuccessIndex(2);
                    } else if (!cleanupPerformed?.duplicates) {
                        setSelectedAction("duplicates")
                        handleCleanupData("duplicates")
                    } else if (!cleanupPerformed?.missing_data) {
                        setSelectedAction("missing_data")
                        handleCleanupData("missing_data")

                    }
                    if (isCheckedHideCleanup) {
                        localStorage.setItem('dont-show-cleanup', 'true');
                        setIsCheckedHideCleanup(false);
                    }
                }}


                isCheckedHideCleanup={isCheckedHideCleanup}
                setIsCheckedHideCleanup={setIsCheckedHideCleanup}
            />
            <AnalysisModal
                open={showAnalysis}
                handleClose={() => {
                    setShowActionCreator(true);
                    setShowAnalysis(false);
                }}
            />
            <TreatmentModal
                open={showTreatmentModal}
                setShowTreatmentModal={setShowTreatmentModal}
                setShowMissingDataModal={setShowMissingDataModal}
            />
        </div >
    );
};

export default Gameplay;
