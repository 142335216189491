import { useRef } from 'react';
import AWS from 'aws-sdk';

const AWS_ACCESS_KEY = 'AKIA4OZIV247IDJBF7PU';
const AWS_SECRET = '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';
const polly = new AWS.Polly();

AWS.config.update({
    region: 'ap-south-1',
    credentials: new AWS.Credentials(AWS_ACCESS_KEY, AWS_SECRET)
});

const useSpeechControl = (messages, isSpeaking, setIsSpeaking, speechEnabled, setSpeechEnabled) => {
    const speechRef = useRef(null);

    const handleToggle = () => {
        if (isSpeaking) {
            // If speech is currently playing, stop it.
            if (speechRef.current) {
                speechRef.current.pause(); // Pause the current audio
                speechRef.current.currentTime = 0; // Reset to the start of the audio
                setIsSpeaking(false); // Update state to indicate speech is not playing
            }
        } else if (speechEnabled) {
            // If speech is not currently playing and speech is enabled, start it.
            const currentMessage = messages[messages.length - 1];
            if (currentMessage) {
                const params = {
                    OutputFormat: 'mp3',
                    Text: currentMessage.text,
                    VoiceId: 'Raveena',
                    SpeechRate: '1.5',
                };

                polly.synthesizeSpeech(params, (err, data) => {
                    if (err) {
                        console.error("Error with Polly: ", err);
                        return;
                    }

                    const audio = new Audio();
                    audio.src = URL.createObjectURL(new Blob([data.AudioStream], { type: 'audio/mp3' }));

                    audio.onplay = () => setIsSpeaking(true); // Update state when audio starts
                    audio.onended = () => setIsSpeaking(false); // Update state when audio ends

                    audio.play();
                    speechRef.current = audio; // Save the reference of the audio object
                });
            }
        }

        // Toggle the speechEnabled state
        setSpeechEnabled((prev) => !prev);
    };

    return { isSpeaking, handleToggle };
};

export default useSpeechControl;