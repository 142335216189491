import React from 'react';
import { dataTypeIcons } from '../../constants';
import { getTypeName } from '@rawgraphs/rawgraphs-core';
import { useDrag } from 'react-dnd';

const ColumnCard = ({
  dimensionName,
  dimensionType,
  commitLocalMapping,
  rollbackLocalMapping,
  currentChartSequence,
  showAnimation,
}) => {

  const [, drag] = useDrag({
    type: 'column', // Ensure type is defined correctly
    item: { id: dimensionName, type: 'column' },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        commitLocalMapping();
      } else {
        rollbackLocalMapping();
      }
    },
  });

  const dimType = getTypeName(dimensionType);
  const DataTypeIcon = dataTypeIcons[dimType];

  return (
    <div
      ref={drag}
      className={`column-card ${showAnimation && currentChartSequence?.columns?.includes(dimensionName) ? 'drag-animation' : ""}`}
    >
      <DataTypeIcon className={"dataType"} />
      <p className='columnName'>{dimensionName}</p>

    </div>
  );
};

export default ColumnCard;
