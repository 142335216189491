import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import "./AboutUs.css";

const AboutUs = () => {
    const containerRefNew = useRef(null);
    return (
        <div ref={containerRefNew} className='about-us-wrapper'>
            <motion.div
                className="about-hero"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
            >
                <div className='hero-background'>
                </div>
            </motion.div>

        </div>
    )
}

export default AboutUs