import React from 'react'
import { motion } from 'framer-motion';
import ChatIcon from "../../assets/img/chat.svg";
import CollapseImage from "../../assets/img/collapse.png"
import Lottie from "lottie-react";
import TypingAnimation from "../../assets/animations/typing-animation.json"

const Chatbox = ({
    messages,
    containerRef,
    showLinkCsvModal,
    showDataCleanupModal,
    showAnalysis,
    onFocusHandler,
    userChatInput,
    onChangeHandler,
    onKeyDownHandler,
    task,
    chatExpanded,
    handleToggleChat,
    isAwaitingGptResponse,
    handleOnClick
}) => {
    return (
        <>
            <div>
                <div
                    className="chat-tooltip"
                >
                </div>
                <div
                    className="chatbodyContainer"
                >
                    <img src={CollapseImage} className="chatbody_collapse" onClick={handleToggleChat} />
                    <div style={{ display: "flex", flexDirection: "column", height: "100%", position: "relative" }}>
                        {chatExpanded && <div style={{ display: "flex", flexDirection: "column", height: "400px", overflowY: "auto" }} ref={containerRef}>
                            {messages?.map((item, index) => {
                                const isLastMessage = index === messages.length - 1 && !showLinkCsvModal && !showDataCleanupModal && !showAnalysis;
                                return (
                                    <motion.div
                                        key={index}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{
                                            duration: 0.6,
                                            ease: "easeOut",
                                            delay: index * 0.2,
                                        }}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        {item?.type === "Sensei" ?
                                            <div
                                                className="user-name-container"
                                            >
                                                <p style={{ fontSize: "12px", fontWeight: 500, color: "#FFF" }}>{item?.type}</p>
                                            </div>
                                            :
                                            <div
                                                className="assistant-name-container"
                                            >
                                                <p style={{ fontSize: "12px", fontWeight: 500, color: "#FFF", marginRight: "2px" }}>{item?.type}</p>
                                            </div>
                                        }
                                        <div>
                                            <motion.p
                                                className="text-message-body"
                                                style={{
                                                    filter: isLastMessage && item?.type === "Sensei" ? "drop-shadow(-4px -2px 10px rgba(0, 255, 133, 0.4))" : "none",
                                                    background: item?.type === "Sensei" ? "#164034" : "#27292C",
                                                    opacity: item?.type === "Sensei" ? "0.44" : "0.76",
                                                    border: isLastMessage ? item?.type === "Sensei" ? "1px solid #00FF85" : "none" : "#27292C"
                                                }}
                                                initial={isLastMessage ? { scale: 0.9, opacity: 0.7 } : { opacity: 0 }}
                                                animate={isLastMessage ? { scale: [0.95, 1, 0.95] } : { scale: [1], opacity: 0.76 }}
                                                transition={
                                                    isLastMessage && item?.type === "Sensei"
                                                        ? {
                                                            duration: 0.8,
                                                            ease: "easeInOut",
                                                            repeat: 4, // Pulsating effect
                                                            repeatType: "reverse",
                                                        }
                                                        : { duration: 0.4, ease: "easeOut" }
                                                }
                                            >
                                                {item?.text}
                                            </motion.p>
                                        </div>
                                    </motion.div>
                                )
                            })}
                        </div>}
                    </div>


                    <div className="chat-input-container">
                        {isAwaitingGptResponse && <div className="response-loader">
                            Sensei is typing
                            <Lottie
                                animationData={TypingAnimation}
                                loop={true}
                                style={{ width: "50px", height: "20px", }}

                            />
                        </div>}
                        {chatExpanded && <input
                            className="chat-input"
                            placeholder="Write your message..."
                            onFocus={() => {
                                onFocusHandler();
                            }}
                            value={userChatInput}
                            onChange={(e) => {
                                onChangeHandler(e?.target?.value)
                            }}
                            onKeyDown={(e) => {
                                onKeyDownHandler(e);
                            }}
                            style={{
                                border: task === "cleanup" || task === "analyse" ? "0.3px solid #01fe87" : "none"
                            }} />}
                        <div
                            className="chatIcon"
                        >
                            <img src={ChatIcon} onClick={() => {
                                handleOnClick()
                            }} />
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default Chatbox