import React from 'react'
import AIM_logo from "../../assets/img/aim_branding.svg"
import DatalyzeLogo from "../../assets/img/dataLyze-new.svg"
import Intsagram_grey from "../../assets/img/ig_grey.svg"
import Linkedin_grey from "../../assets/img/linkedin_grey.svg"
import X_grey from "../../assets/img/x_grey.svg"
import { useNavigate } from 'react-router-dom'

const cancellation = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", padding: '20px', margin: '0 auto', color: '#fff', background: "#010C12", gap: "20px", textAlign: "justify" }}>
                <h1 style={{ textTransform: "capitalize" }}>Subscription Fees, Cancellation and Refund Policy</h1>
                <h2 style={{ textTransform: "capitalize" }}>Subscription Fees</h2>
                <ol>
                    <li>Credit card holder&apos;s account is automatically charged on the same date as the original transaction date on each corresponding month or on the final date of month if no corresponding date (January 31, 2020 subscription renews on February 28, 2020).</li>
                    <li>Discounts, rebates or other special offers are only valid for the initial term; subscriptions renew at the then-current full subscription rates.</li>
                    <li>MachineHack may terminate the subscription and these terms if it is unable to renew the subscription based on inaccurate or outdated credit card information.</li>
                    <li>Right of access granted under these Terms is effective only upon payment of the subscription fees.
                    </li>
                </ol>
                <h2 style={{ textTransform: "capitalize" }}>Cancelling Account</h2>
                <ol>
                    <li>You can cancel your subscription from your account profile page.
                    </li>
                    <li>Discounts, rebates or other special offers are only valid for the initial term; subscriptions renew at the then-current full subscription rates.</li>
                    <li>The only valid method for canceling your Plan is via the cancellation link provided on your user &quot;dashboard&quot; page, accessible after logging in to the MachineHack Website. Requests to cancel by e-mail or phone are not considered, and do not accomplish, cancellation.
                    </li>
                    <li>Right of access granted under these Terms is effective only upon payment of the subscription fees.
                    </li>
                    <li>Once you cancel your membership subscription, you will not lose access immediately.
                    </li>
                    <li>Your membership will continue through the end of your current charge cycle. For example: If your credit card is charged on the 10th of the month and you cancel on April 25th, you will not lose access until the May 10th.
                    </li>
                </ol>
                <h2 style={{ textTransform: "capitalize" }}>Refunds</h2>
                <ol>
                    <li>Subscription fees are non-refundable; except that you may cancel the renewed subscription.
                    </li>
                </ol>
            </div>
            <footer style={{
                width: '100%',
                height: "400px",
                background: "#040404",
                position: "relative",
                display: "flex",
                flexDirection: 'column',
                padding: "80px 150px 50px 120px"
            }}>

                <div style={{
                    display: "flex",
                    flexDirection: 'row',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                    }}>
                        <img
                            src={DatalyzeLogo}
                        />
                        <img
                            onClick={() => {
                                window.open("https://analyticsindiamag.com/", "_blank")
                            }}
                            src={AIM_logo}
                            style={{
                                width: "120px",
                                alignSelf: "end",
                                marginTop: "5px",
                                cursor: "pointer"
                            }}
                        />
                    </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        marginLeft: "150px"
                    }}>
                        <p style={{
                            color: '#5C5C5E',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            textTransform: 'uppercase',
                            marginBottom: "22px"
                        }}>Explore</p>

                        <p
                            onClick={() => {
                                window.open("https://machinehack.com/", "_blank")
                            }}
                            style={{
                                color: 'rgba(255, 255, 255, 0.6)',
                                fontFamily: '"Plus Jakarta Sans"',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '34px',
                                letterSpacing: '-1px',
                                cursor: "pointer"
                            }}>About MachineHack
                        </p>
                        <p onClick={() => {
                            window.open("https://machinehack.com/", "_blank")
                        }} style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>About Datalyze</p>
                        <p style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Problem Statements</p>
                    </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        marginLeft: "150px"
                    }}>
                        <p style={{
                            color: '#5C5C5E',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            textTransform: 'uppercase',
                            marginBottom: "22px"
                        }}>Legal</p>

                        <a onClick={() => {
                            navigate("/terms")
                        }} style={{
                            background: "black",
                            border: "none",
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Terms of Service</a>
                        <a onClick={() => {
                            navigate("/privacy-policy")
                        }} style={{
                            background: "black",
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer",
                        }}>Privacy Policy</a>
                        <a onClick={() => {
                            navigate("/cancellation")
                        }} style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Cancellation & Refund Policy</a>
                    </div>


                    <button
                        onClick={() => {
                            navigate("/contact-us")
                        }}
                        style={{
                            display: 'flex',
                            width: '218.565px',
                            height: '49.783px',
                            padding: '17.391px 34.783px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10.87px',
                            flexShrink: 0,
                            borderRadius: '50px',
                            background: 'linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)',
                            color: '#000',
                            textAlign: 'center',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '15.217px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '100%',
                            border: "none",
                            position: "absolute",
                            right: "100px",
                            top: "180px",
                            cursor: "pointer"
                        }}>
                        Contact Us
                    </button>
                </div>
                <div style={{
                    width: "100%",
                    height: "1px",
                    background: "#1B1B1B",
                    paddingLeft: "120px",
                    paddingRight: "80px",
                    marginTop: "50px",
                }}></div>
                <div style={{ width: "100%", height: "50px" }}></div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                }}>
                    <p style={{
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontFamily: '"Plus Jakarta Sans"',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '34px',
                        letterSpacing: '-1px',
                    }}>© Copyright Kruxonomy Consulting Pvt Ltd</p>


                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <img
                            onClick={() => {
                                window.open("https://www.instagram.com/machinehack/", "_blank")
                            }}
                            src={Intsagram_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                        <img
                            onClick={() => {
                                window.open("https://www.linkedin.com/company/machine-hack/", "_blank")
                            }}
                            src={Linkedin_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                        <img
                            onClick={() => {
                                window.open("https://x.com/JoinMachinehack", "_blank")
                            }}
                            src={X_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                    </div>

                    <div style={{
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontFamily: '"Plus Jakarta Sans"',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '34px',
                        letterSpacing: '-1px',
                    }}>
                        © 2025 All rights reserved
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default cancellation