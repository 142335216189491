let SampleList = [
    {
        name: 'Letter Frequency By Language',
        category: 'Matrix Plot',
        defaultBarIndex: 6,
        url: 'Letter Frequency By Language',
        delimiter: '\t',
        sourceName: 'letter-frequency-by-language',
        sourceURL:
            'https://data.cityofnewyork.us/Public-Safety/NYPD-Hate-Crimes/bqiq-cu78',
    },
    {
        name: 'Happiness Index',
        category: 'Multiset Bar chart',
        url: 'Multiset Bar chart - Happiness Index',
        defaultBarIndex: 1,
        delimiter: '\t',
        sourceName: 'happiness-index',
        sourceURL:
            'https://data.cityofnewyork.us/Public-Safety/NYPD-Hate-Crimes/bqiq-cu78',
    },
    {
        name: 'Netflix Original Series 2013/2017',
        category: 'Bar chart',
        url: 'Netflix Original Series 2013/2017',
        defaultBarIndex: 0,
        delimiter: '\t',
        sourceName: 'netflix-0riginal-series',
        sourceURL: 'https://data.world/mattschroyer/netflix-original-series',
    },
    {
        name: 'Weather in newyork 2012-2015',
        category: 'Violin Plot',
        url: 'Violin Plot - Weather in New York 2012-2015',
        defaultBarIndex: 7,
        delimiter: '\t',
        sourceName: 'weather-in-newyork-2012/2015',
        sourceURL:
            'https://data.ny.gov/w/gkgz-nw24/caer-yrtv?cur=S3JXBWlXCbs&from=SAa8_R9mOdD',
    },
    {
        name: 'Highest grossing movies in history',
        category: 'Bubble chart',
        url: 'Top50Movies',
        defaultBarIndex: 4,
        delimiter: '\t',
        sourceName: 'highest-grossing-movies-in-history',
        sourceURL:
            'https://en.wikipedia.org/w/index.php?title=List_of_highest-grossing_films&oldid=1023491946',
    },
    {
        name: 'GDP Sector Composition',
        category: 'Stacked Bar Chart',
        url: 'Stacked Bar Chart - GDP Sector Composition',
        defaultBarIndex: 2,
        delimiter: '\t',
        sourceName: 'gdp-Sector-composition',
        sourceURL:
            'https://dati.comune.milano.it/dataset/ds74-popolazione-residenti-stranieri-cittadinanza',
    },
]


export default SampleList;