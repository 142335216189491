import React from 'react';
import { motion } from 'framer-motion';
import { Card, Box, Typography } from '@mui/material';
import Coins from "../assets/img/Coins.png";
import './CoinsCard.css';

const CoinsCard = ({ coinsCount }) => {

    return (
        <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 50, damping: 15 }}
        >
            <Card
                className="coins-card"
                sx={{
                    height: "75px",
                    borderRadius: "16px",
                    borderTop: "1px solid rgba(255, 255, 255, 0.04)",
                    backgroundColor: "rgba(26, 29, 33, 0.4)",
                    boxShadow:
                        "16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0 10px",
                    boxSizing: "border-box",
                    width: "323px",
                    mt: 1,
                }}
            >
                <Box
                    sx={{
                        width: "108px",
                        height: "100px",
                        mb: 5,
                    }}
                >
                    <img
                        className="coin-image"
                        src={Coins}
                        alt="Coins"
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                    />
                </Box>
                {/* <Typography
                    className="total-coins-text"
                    variant="body2"
                    sx={{
                        fontSize: "15px",
                        fontWeight: 600,
                        lineHeight: "24px",
                        color: "#8E8C91",
                    }}
                >
                    <span
                        className="text-number"
                        style={{
                            fontSize: "28px",
                            fontWeight: 800,
                            color: "#fff",
                        }}
                    >
                        <motion.span>{coinsCount}</motion.span>
                    </span>
                    <span className="total">Total</span>
                    <br />
                    <div className="coins-text">Coins Available</div>
                </Typography> */}
                <h3>
                    <motion.span
                        className="coins-count-text"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, count: [0, coinsCount] }}
                        transition={{ duration: 1, ease: "easeOut", times: [0, 1] }}
                    >
                        {coinsCount}
                    </motion.span>
                    <span style={{
                        fontSize: "15px",
                        fontWeight: 600,
                        lineHeight: "24px",
                        color: "#8E8C91",

                    }}>Total <br /> Coins Available</span>
                </h3>

                <Box
                    className="redeem-box"
                    sx={{
                        display: { xs: "none", sm: "none", md: "flex" },
                        position: "relative",
                        right: "-10px",
                        backgroundColor: "#F97403",
                        width: "85px",
                        height: "30px",
                        borderTopLeftRadius: "13px",
                        borderBottomLeftRadius: "13px",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#1E2118",
                        }}
                    >
                        Redeem
                    </Typography>
                </Box>
            </Card>
        </motion.div>
    );
};

export default CoinsCard;