import React from 'react';
import AIM_logo from "../../assets/img/aim_branding.svg"
import DatalyzeLogo from "../../assets/img/dataLyze-new.svg"
import Intsagram_grey from "../../assets/img/ig_grey.svg"
import Linkedin_grey from "../../assets/img/linkedin_grey.svg"
import X_grey from "../../assets/img/x_grey.svg"
import { useNavigate } from 'react-router-dom'

const privacy = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", padding: '20px', margin: '0 auto', color: '#fff', background: "#010C12", gap: "20px", textAlign: "justify" }}>
                <h1>Privacy Policy</h1>

                <p style={{ textTransform: "capitalize" }}>Welcome, and thank you for your interest in Datalyze Inc. (“MachineHack”), and the Services (including the Website and everything provided through it), as described in the Datalyze terms and conditions, http://www.machinehack.com/terms/ (“Terms”). This Privacy Policy is incorporated into and is a part of the Terms. Capitalized terms used, but not defined in this policy have the meanings given to them elsewhere in the Terms.</p>
                <p style={{ textTransform: "capitalize" }}>Please read this Privacy Policy carefully, as it describes what information we gather from you, and how we use and disclose that information. By clicking “accept” or “get started” in connection with the Terms and/or by accessing or using the Services, you expressly consent to the information handling practices described in this policy. This Privacy Policy only covers MachineHack’s practices regarding personal information, and not information more generally. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally.</p>
                <h2 style={{ textTransform: "capitalize" }}>Member-Provided Information:</h2>
                <p>You may provide to Datalyze what is generally called “personally identifiable” information (such as your name, email address, postal mailing address, home/mobile telephone number) when using the Services, such as when you register for the Member Account, and when you submit Entries.</p>
                <h2 style={{ textTransform: "capitalize" }}>“Cookies” Information:</h2>
                <p>When you use the Services, we may send one or more cookies – small text files containing a string of alphanumeric characters – to your computer. Datalyze may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to the Services. Persistent cookies can be removed. Please review your web browser “Help” file to learn the proper way to modify your cookie settings. Please note that if you delete, or choose not to accept, cookies from the Services, you may not be able to utilize the features of the Services to their fullest potential.</p>
                <h2 style={{ textTransform: "capitalize" }}>“Flash Cookies” Information:</h2>
                <p>Flash and other browser plugin applications use technology similar to cookies to enable the storage and recall of settings, preferences and usage data, and other information. Because they are stored in separate software applications, these types of cookies are not managed through your web browser’s cookie management interface. Datalyze may employ such techniques, including Adobe Flash Cookies (also called “Local Shared Objects”) to track information related to your interaction with features of the Services through which Flash-based content is provided. You can access various Flash Cookie management tools and configure certain privacy and security settings for your Flash Player from Adobe’s web site directly.

                </p>
                <h2 style={{ textTransform: "capitalize" }}>“Automatically Collected” Information:</h2>
                <p>When you use the Services or open one of our HTML emails, we may automatically record certain information from your web browser by using different types of technology, including “clear gifs” or “web beacons.” This “automatically collected” information may include Internet Protocol address (“IP Address”) or other device address or ID, web browser and/or device type, the web pages or sites that you visit just before or just after the Services, the pages you view on the Services, and the dates and times that you visit the Services.</p>
                <h2 style={{ textTransform: "capitalize" }}>Linked Website Information:</h2>
                <p>When you link an account you may have on a third party service (such as your third party social networking site) to your Member Account on the Services, Datalyze may store the access credentials you provide, and will have access to and may collect any information you may have stored in connection with that account that you have configured that service to make available, including personally identifiable information and friends lists. By linking your accounts in this manner, you are authorizing Datalyze as your agent to access and use your account on the third party service in connection with your use of the Services.

                </p>
                <h2 style={{ textTransform: "capitalize" }}>Third Party Web Beacons:</h2>
                <p>We may also implement third party content, such as advertising, on the Services that uses “clear gifs,” “web beacons,” or other similar techniques, which allow the third party content provider to read and write cookies (including Flash Cookies) to your browser, or implement similar tracking mechanisms, in connection with your viewing of that third party content displayed on the Services. This information is collected directly by the third party, and Datalyze does not participate in that data transmission. Information collected by third parties in this manner is subject to that third party’s own data collection, use, and disclosure policies.</p>
                <h2 style={{ textTransform: "capitalize" }}>Information from Other Sources:</h2>
                <p>We may also obtain information, including personally identifiable information, from third parties and sources other than the Services, such as advertisers, or other Members who import your email address from their address book. We may also, at your direction, receive information from third party service that provide a mechanism to expose information you have provided to such third party through the use of an application program interface (API), such as Facebook Connect and the LinkedIn API. If we combine or associate information from other sources with personally identifiable information that we collect through the Services, we will treat the combined information as personally identifiable information in accordance with this Privacy Policy.</p>
                <h2 style={{ textTransform: "capitalize" }}>The Way Datalyze Uses Information:</h2>
                <p>Datalyze uses the information you provide or that we collect to operate, maintain, enhance, and provide all of the features and services found on the Services. We will use your email address to contact you with respect to Entries, Awards, marketing/ advertising campaigns and otherwise in connection with Competitions, for any administrative and customer service purposes, to address intellectual property infringement, rights of privacy, or defamation issues, or regarding Postings.</p>
                <p>Datalyze uses all of the information that you provide or that we collect to understand and analyze the usage trends and preferences of our Members, to improve the way the Services works and looks, and to create new features and functionality.</p>
                <p>Datalyze may use “automatically collected” information and “cookies” information to: (a) personalize our services, such as remembering your information so that you will not have to re-enter it during your visit or the next time you visit the Services; (b) provide customized third party content, and information; (c) monitor and analyze the operation and effectiveness of the Services and related activities; (d) monitor aggregate site usage metrics such as total number of visitors and pages viewed; and (e) track your status in any Competitions or other activities.</p>
                <p>Datalyze may use your email address or other personally identifiable information to send you i) messages about the Services or communications from other Members, ii) promotional messages related to the Services and the activities of third parties we work with, and iii) messages on behalf of other Members who think you may be interested in the Services. You will have the ability to opt-out of receiving any such communications, either through links provided in the messages, or by updating your Member Account settings through the Services.</p>

                <h2 style={{ textTransform: "capitalize" }}>When Datalyze Discloses Information:</h2>
                <p>We are not in the business of selling your information. We do, however, disclose your personally identifiable information in a variety of circumstances in connection with developing and maintaining the Services and operating or supporting the operation of Competitions. For example:</p>
                <p>By its nature, the Services enables Members to communicate in a variety of ways, including between Members. Any personally identifiable information that you voluntarily choose to include in an area of the Services accessible to other Members, such as a public profile page or in a Posting will be available to other Members who access that content. This also applies to information you choose to make available through features that connect to third party services, if applicable. Once you make your personally identifiable information available any of these ways, it may be collected and used by the recipients without restriction. We urge Members to exercise common sense, prudence and good judgment about what personal information to make available to others through the Services.</p>
                <p>Datalyze may disclose your personally identifiable information to the Competition Host and/or Competitors, as applicable, to enable your participation in and the operation of Competitions.</p>
                <p>Datalyze works with third party service providers to provide website hosting and maintenance as well as other services for us. These third parties may have access to or process your personally identifiable information as a result of performing the services they were engaged to perform. Generally, these disclosures are made under terms comparable to this policy, and the recipients are limited to using the information the purpose for which it was provided.</p>
                <p>Datalyze may enable Members to communicate directly with other Members of the Services. Any personally identifiable information that you voluntarily choose to include in a communication with other Members, will be available to such other Members.</p>
                <p>Datalyze may also disclose your information if required to do so by law or in the good-faith belief that such action is necessary or useful to comply with applicable laws, to respond to a court order, judicial or other government subpoena or warrant, or to otherwise assist or cooperate with law enforcement activity or other similar activities in connection with internal or coordinated fraud detection and prevention, and the protection or enforcement of third party rights.</p>
                <p>Datalyze works to promote a safe and reliable service for all Members. We reserve the right to disclose your information that we believe, in good faith, is appropriate or necessary to take precautions against liability; to protect Datalyze and others from fraudulent, abusive, predatory, or unlawful uses or activity; to investigate and defend ourselves against any third party claims or allegations; to protect the security or integrity of the Services; or to protect or vindicate the rights, property, or personal safety of Datalyze, our Members, or others.</p>
                <h2 style={{ textTransform: "capitalize" }}>Your Choices</h2>
                <p>You may, of course, decline to share certain personally identifiable information with Datalyze, in which case Datalyze may not be able to provide to you some of the features and functionality found on the Services. You may update, correct, or delete your profile information and preferences at any time by accessing your account settings page or editing your profile through the Services. Please note that while your changes are reflected promptly in active Member databases, we may retain all information you submit for a variety of purposes, including backups and archiving, prevention of fraud and abuse, and analytics.</p>
                <p>To protect your privacy and security, we take reasonable steps to verify your identity before granting you account access or making corrections to your information. YOU ARE RESPONSIBLE FOR MAINTAINING THE SECRECY OF YOUR UNIQUE PASSWORD AND ACCOUNT INFORMATION AT ALL TIMES.</p>
                <p>We may now or in the future make certain tools and features for configuring your privacy settings available to you through the Services, but any such features and settings we may provide do not guarantee the anonymity or confidentiality of any information, including personally identifiable information and Postings you provide or submit to the Services.</p>

                <h3 style={{ textTransform: "none" }}>Third Party Websites:</h3>
                <p>The Services contains links to Web sites and services provided by third parties. Any personally identifiable information you provide on third party sites or services is provided directly to that third party and is subject to that third party’s policies, if any, governing privacy and security. We are not responsible for the content or privacy and security practices and policies of third party sites or services to which links are displayed on the Services. We encourage you to learn about third parties’ privacy and security policies before providing them with personally identifiable information.</p>

                <h2 style={{ textTransform: "capitalize" }}>Our Commitment to Children’s Privacy:</h2>
                <p>Protecting the privacy of young children is especially important. For that reason, Datalyze does not knowingly collect or maintain personally identifiable information from persons under 13 years-of-age, and no part of the Services is directed to persons under 18. IF YOU ARE UNDER 18 YEARS OF AGE, THEN PLEASE DO NOT USE OR ACCESS THE SERVICES AT ANY TIME OR IN ANY MANNER. If Datalyze learns that personally identifiable information of persons less than 13-years-of-age has been collected on the Services without verifiable parental consent, then Datalyze will take the appropriate steps to delete this information. If you are a parent or guardian and discover that your child under the age of 13 has obtained a User Account on the Services, then you may alert Datalyze at Datalyze Support (Datalyze.com/contact) and request that Datalyze delete that child’s personally identifiable information from its systems.</p>
                <h2 style={{ textTransform: "capitalize" }}>Our Commitment to Data Security:</h2>

                <p>Datalyze uses a variety of physical, managerial, and technical safeguards designed to improve the security of our systems and your personally identifiable information. We cannot, however, ensure or warrant the security of any information you transmit to Datalyze, nor can we guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. You transfer your information to Datalyze at your own risk.</p>
                <p>If Datalyze learns of a security systems breach, then we will attempt to notify you electronically so that you can take appropriate protective steps. Depending on where you live, you may have a legal right to receive notice of a security breach in writing. If you’d like to receive such a notice please notify us at (Datalyze.com/contact).</p>

                <h2 style={{ textTransform: "capitalize" }}>International Visitors:</h2>
                <p>The Services is intended for Members located in India. Your information may be stored and processed in any country in which Datalyze and its affiliates maintain facilities, including India and other locations. In this regard, or for purposes of sharing or disclosing data in accordance with this Privacy Policy, Datalyze reserves the right to transfer, store, and process your information outside of your country. Your acceptance of the Terms includes your consent to any such transfer, storage, and processing of your information outside of your country.</p>
                <h2 style={{ textTransform: "capitalize" }}>In the Event of Merger or Sale:</h2>
                <p>In the event that all or a portion of Datalyze or its assets are acquired by or merged with a third party entity, we reserve the right, in any of these circumstances, to transfer or assign the information that we have collected from Members in connection with such merger, acquisition, sale, or other change of control.</p>
                <h2 style={{ textTransform: "capitalize" }}>Changes and Updates to this Privacy Policy:</h2>
                <p>Please revisit this page periodically to stay aware of any changes to this Privacy Policy, which may be revised at MachineHack’s sole discretion, as provided in the Terms. Note that our amended Privacy Policy will take become effective on a going forward basis as set forth in the Terms, except that (i) unless you agree otherwise, we will use your personally identifiable information in the manner described in the Privacy Policy in effect when we received that information and (ii) if you do not agree with any changes to the Privacy Policy, you must terminate your Membership and cease use of the Services. Your continued use of the Services after revised Privacy Policy has become effective indicates that you have read, understood and agreed to the current version of the Privacy Policy.</p>

                <h2 style={{ textTransform: "capitalize" }}>Datalyze Contact Information:</h2>
                <p>Please contact Datalyze with any questions or comments about this Privacy Policy, your personally identifiable information, our use and disclosure practices, or your consent choices through Datalyze Support (Datalyze.com/contact).</p>
                <p>Contact Emails : info@analyticsindiamag.com</p>


            </div>
            <footer style={{
                width: '100%',
                height: "400px",
                background: "#040404",
                position: "relative",
                display: "flex",
                flexDirection: 'column',
                padding: "80px 150px 50px 120px"
            }}>

                <div style={{
                    display: "flex",
                    flexDirection: 'row',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                    }}>
                        <img
                            src={DatalyzeLogo}
                        />
                        <img
                            onClick={() => {
                                window.open("https://analyticsindiamag.com/", "_blank")
                            }}
                            src={AIM_logo}
                            style={{
                                width: "120px",
                                alignSelf: "end",
                                marginTop: "5px",
                                cursor: "pointer"
                            }}
                        />
                    </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        marginLeft: "150px"
                    }}>
                        <p style={{
                            color: '#5C5C5E',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            textTransform: 'uppercase',
                            marginBottom: "22px"
                        }}>Explore</p>

                        <p
                            onClick={() => {
                                window.open("https://machinehack.com/", "_blank")
                            }}
                            style={{
                                color: 'rgba(255, 255, 255, 0.6)',
                                fontFamily: '"Plus Jakarta Sans"',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '34px',
                                letterSpacing: '-1px',
                                cursor: "pointer"
                            }}>About MachineHack
                        </p>
                        <p onClick={() => {
                            window.open("https://machinehack.com/", "_blank")
                        }} style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>About Datalyze</p>
                        <p style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Problem Statements</p>
                    </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        marginLeft: "150px"
                    }}>
                        <p style={{
                            color: '#5C5C5E',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            textTransform: 'uppercase',
                            marginBottom: "22px"
                        }}>Legal</p>

                        <a onClick={() => {
                            navigate("/terms")
                        }} style={{
                            background: "black",
                            border: "none",
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Terms of Service</a>
                        <a onClick={() => {
                            navigate("/privacy-policy")
                        }} style={{
                            background: "black",
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer",
                        }}>Privacy Policy</a>
                        <a onClick={() => {
                            navigate("/cancellation")
                        }} style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Cancellation & Refund Policy</a>
                    </div>


                    <button
                        onClick={() => {
                            navigate("/contact-us")
                        }}
                        style={{
                            display: 'flex',
                            width: '218.565px',
                            height: '49.783px',
                            padding: '17.391px 34.783px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10.87px',
                            flexShrink: 0,
                            borderRadius: '50px',
                            background: 'linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)',
                            color: '#000',
                            textAlign: 'center',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '15.217px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '100%',
                            border: "none",
                            position: "absolute",
                            right: "100px",
                            top: "180px",
                            cursor: "pointer"
                        }}>
                        Contact Us
                    </button>
                </div>
                <div style={{
                    width: "100%",
                    height: "1px",
                    background: "#1B1B1B",
                    paddingLeft: "120px",
                    paddingRight: "80px",
                    marginTop: "50px",
                }}></div>
                <div style={{ width: "100%", height: "50px" }}></div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                }}>
                    <p style={{
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontFamily: '"Plus Jakarta Sans"',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '34px',
                        letterSpacing: '-1px',
                    }}>© Copyright Kruxonomy Consulting Pvt Ltd</p>


                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <img
                            onClick={() => {
                                window.open("https://www.instagram.com/machinehack/", "_blank")
                            }}
                            src={Intsagram_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                        <img
                            onClick={() => {
                                window.open("https://www.linkedin.com/company/machine-hack/", "_blank")
                            }}
                            src={Linkedin_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                        <img
                            onClick={() => {
                                window.open("https://x.com/JoinMachinehack", "_blank")
                            }}
                            src={X_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                    </div>

                    <div style={{
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontFamily: '"Plus Jakarta Sans"',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '34px',
                        letterSpacing: '-1px',
                    }}>
                        © 2025 All rights reserved
                    </div>
                </div>
            </footer>
        </div>

    )
}

export default privacy