import {
  // alluvialdiagram,
  // arcdiagram,
  barchart,
  barchartmultiset,
  barchartstacked,
  // beeswarm,
  boxplot,
  bubblechart,
  // bumpchart,
  // chorddiagram,
  // circlepacking,
  // circularDendrogram,
  // contourPlot,
  // convexHull,
  // dendrogram,
  // ganttChart,
  // hexagonalBinning,
  // horizongraph,
  linechart,
  matrixplot,
  // parallelcoordinates,
  // piechart,
  // radarchart,
  // sankeydiagram,
  // slopechart,
  // streamgraph,
  // sunburst,
  // treemap,
  violinplot,
  // voronoidiagram,
  // voronoitreemap,
} from '@rawgraphs/rawgraphs-charts'

// import { calendarHeatmap } from '@rawgraphs/rawgraphs-calendar-heatmap'

// New charts, not included into first release.
// Comment at necessity.
let charts = [
  // alluvialdiagram,
  // arcdiagram,
  barchart,
  barchartmultiset,
  barchartstacked,
  // beeswarm,
  boxplot,
  bubblechart,
  // bumpchart,
  // chorddiagram,
  // circlepacking,
  // circularDendrogram,
  // contourPlot,
  // convexHull,
  // dendrogram,
  // ganttChart,
  // hexagonalBinning,
  // horizongraph,
  linechart,
  matrixplot,
  // parallelcoordinates,
  // piechart,
  // radarchart,
  // sankeydiagram,
  // slopechart,
  // streamgraph,
  // sunburst,
  // treemap,
  violinplot,
  // voronoidiagram,
  // voronoitreemap,
]

export default charts
