import React, { useRef, useState, useEffect } from 'react'
import "./ContactUs.css";
import { motion } from 'framer-motion';
import VariableProximity from './Animations/VariableProximity';
import bg from "../assets/img/bg-contact-us.svg"
import linkedIn from "../assets/img/linkedIn.png"
import Instagram from "../assets/img/ig.png"
import X from "../assets/img/x.png"
import DatalyzeLogo from "../assets/img/dataLyze-new.svg"
import AIM_logo from "../assets/img/aim_branding.svg"
import Intsagram_grey from "../assets/img/ig_grey.svg"
import Linkedin_grey from "../assets/img/linkedin_grey.svg"
import X_grey from "../assets/img/x_grey.svg"
import { CircularProgress } from '@mui/material'
import { useSendEmailMutation } from '../store/services/datalyzeProblems'
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        sender: "",
        subject: "",
        body: "",
    })
    const [isLoadingSendingEmail, setIsLoadingSendingEmail] = useState(false);
    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const containerRefNew = useRef(null);

    const [sendEmail] = useSendEmailMutation()

    const contactSectionRef = useRef(null);

    useEffect(() => {
        if (contactSectionRef.current) {
            contactSectionRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    let assitanceArr = [
        {
            title: "Technical Support",
            description: "Encountering an issue? Our team is ready to assist.",
            image: "https://machinehack-be.s3.ap-south-1.amazonaws.com/assests/datalyze_assets/techinical.png"
        }, {
            title: "General Inquiries",
            description: "Have a question about Datalyze? Let us know!",
            image: "https://machinehack-be.s3.ap-south-1.amazonaws.com/assests/datalyze_assets/inquiries.png"
        }, {
            title: "Business Collaborations",
            description: "Interested in partnering with us? We’dlove to hear from you.",
            image: "https://machinehack-be.s3.ap-south-1.amazonaws.com/assests/datalyze_assets/collaborations.png"
        }
    ]

    const handleChangeInput = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleSubmit = async () => {
        setIsLoadingSendingEmail(true);
        try {
            const result = await sendEmail(formData);
            if (result?.data?.message === "success") {
                setIsLoadingSendingEmail(false);
                setShowEmailSuccess(true);
                setFormData({
                    sender: "",
                    subject: "",
                    body: "",
                })
                setTimeout(() => {
                    setShowEmailSuccess(false);
                }, 4000);
            } else {
                setIsLoadingSendingEmail(false);
            }
        } catch (err) {
            setIsLoadingSendingEmail(false);
        }
    };

    return (
        <div ref={containerRefNew} className='contact-us-wrapper'>
            <div style={{
                width: "100%",
                height: "100px",
                backgroundColor: "#040404",
                zIndex: "999",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: "70px",
                paddingRight: "70px"
            }}>
                <img
                    src={DatalyzeLogo}
                    alt={"machinehack"} style={{
                        width: "174px",
                        height: "51px",
                        cursor: 'pointer',
                        marginTop: "10px"
                    }}
                    onClick={() => {
                        navigate("/")
                    }}
                />

                <div style={{ display: "flex", flexDirection: "row", marginTop: "15px" }}>
                    <button
                        style={{
                            width: "72px",
                            height: "32px",
                            borderRadius: "50px",
                            background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                            color: "#040404",
                            fontFamily: "Plus Jakarta Sans",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "16.5px", /* 150% */
                            letterSpacing: "-0.11px",
                            textTransform: "uppercase",
                            border: "none",
                            marginRight: "15px",
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            navigate("/login")
                        }}
                    >
                        SIGN IN
                    </button>

                    <button style={{
                        width: "102px",
                        height: "32px",
                        borderRadius: "9999px",
                        background: "var(--huly-io-woodsmoke, #090A0C)",
                        border: "var(--stroke-weight-1, 1px) solid rgba(255, 255, 255, 0.30)",
                        color: "#FFF", // fallback for var(--huly-io-white)
                        fontFamily: "Plus Jakarta Sans",
                        fontSize: "11px", // fallback for var(--font-size-11)
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "16.5px", // fallback for var(--line-height-16_5)
                        letterSpacing: "-0.11px", // fallback for var(--letter-spacing--0_11)
                        textTransform: "uppercase",
                        cursor: "pointer",
                    }}
                        onClick={() => {
                            navigate("/contact-us")
                        }}
                    >
                        CONTACT US
                    </button>
                </div>

            </div>
            <motion.div
                className="contact-hero"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
            >
                <div className='hero-background'>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }} // Start invisible and 50px below
                        animate={{ opacity: 1, y: 0 }} // Move to normal position
                        transition={{ duration: 0.8, ease: "easeOut" }} // Smooth transition
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >

                        <VariableProximity
                            label={'Get in touch with'}
                            className={'variable-proximity-demo'}
                            fromFontVariationSettings="'wght' 600, 'opsz' 9"
                            toFontVariationSettings="'wght' 1000, 'opsz' 40"
                            containerRef={containerRefNew}
                            radius={100}
                            falloff='linear'
                            style={{ fontSize: "64px", zIndex: "999" }}
                        />
                    </motion.div>
                    <VariableProximity
                        label={'DATALYZE'}
                        className={'variable-proximity-demo'}
                        fromFontVariationSettings="'wght' 600, 'opsz' 9"
                        toFontVariationSettings="'wght' 1000, 'opsz' 40"
                        containerRef={containerRefNew}
                        radius={100}
                        falloff='linear'
                        style={{
                            fontSize: "64px",
                            zIndex: "999",
                            background: 'linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    />

                    <motion.p
                        className="contact-us-description"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.8, delay: 0.5 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >
                        Have questions or need assistance? We’re here to help.<br />
                        Reach out to us for any inquiries, support, demo<br />
                        or collaboration opportunities.
                    </motion.p>
                </div>
            </motion.div>

            <section className='contact-main-section'>
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                >
                    <VariableProximity
                        label={'Support & Assistance'}
                        className={'variable-proximity-demo'}
                        fromFontVariationSettings="'wght' 600, 'opsz' 9"
                        toFontVariationSettings="'wght' 1000, 'opsz' 40"
                        containerRef={containerRefNew}
                        radius={100}
                        falloff='linear'
                        style={{ fontSize: "64px", zIndex: "999" }}
                    />
                </motion.div>

                <motion.div
                    className='assistance-contianer'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.6 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                >
                    {assitanceArr?.map((item) => {
                        return (
                            <motion.div
                                key={item?.title}
                                className='assistance-item'
                                initial={{ opacity: 0, x: -50 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.6, ease: "easeOut" }}
                            >
                                <h4 className='assistance-title'>
                                    {item?.title}
                                </h4>
                                <p className='assistance-description'>{item?.description}</p>
                                <div className='assistance-img-wrapper'>
                                    <img className='assistance-img' src={item?.image} alt={item?.title} />
                                </div>
                            </motion.div>
                        )
                    })}
                </motion.div>
            </section>

            <section ref={contactSectionRef} className='contact-details-section'>
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                >
                    <VariableProximity
                        label={'Contact Form'}
                        className={'variable-proximity-demo'}
                        fromFontVariationSettings="'wght' 600, 'opsz' 9"
                        toFontVariationSettings="'wght' 1000, 'opsz' 40"
                        containerRef={containerRefNew}
                        radius={100}
                        falloff='linear'
                        style={{ fontSize: "64px", zIndex: "999" }}
                    />
                </motion.div>
                <motion.p
                    className="contact-us-description"
                    style={{ marginTop: "15px" }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.5 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                >
                    Fill out the form below, and our team will get back to you promptly:
                </motion.p>

                <motion.div
                    className='formContainer'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.7 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                >
                    <input
                        name="sender"
                        onChange={(e) => handleChangeInput(e)}
                        placeholder='Your Email' className='input-styles' />
                    <input
                        name="subject"
                        onChange={(e) => handleChangeInput(e)}
                        placeholder='Subject' className='input-styles' style={{ marginTop: "30px" }} />
                    <textarea
                        name="body"
                        onChange={(e) => handleChangeInput(e)}
                        placeholder="Message"
                        className="input-styles"
                        style={{ marginTop: "30px", height: "150px" }}
                    ></textarea>

                    <button
                        className='submit-btn'
                        style={{ zIndex: "100" }}
                        onClick={() => {
                            handleSubmit()
                        }}>
                        {isLoadingSendingEmail ? <CircularProgress size={24} sx={{ margin: "0 auto", color: "#55E7B2" }} /> : "Submit"}
                    </button>
                </motion.div>
            </section>

            <motion.section
                className="contact-footer-section"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.8 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
            >
                <div className='social-media-btn'>
                    Connect with us
                </div>
                <img
                    src={bg}
                    className='contact-footer-bg'
                    alt="Footer Background"
                />
                <div className='social-media-icon-container'>
                    <img onClick={() => {
                        window.open("https://www.linkedin.com/company/machine-hack/", "_blank")
                    }} src={linkedIn} style={{ objectFit: "contain", marginRight: "50px", zIndex: 100, cursor: "pointer" }} />
                    <img onClick={() => {
                        window.open("https://x.com/JoinMachinehack", "_blank")
                    }} src={X} style={{ objectFit: "contain", marginRight: "50px", zIndex: 100, cursor: "pointer" }} />
                    <img onClick={() => {
                        window.open("https://www.instagram.com/machinehack/", "_blank")
                    }} src={Instagram} style={{ objectFit: "contain", zIndex: 100, cursor: "pointer" }} />
                </div>
            </motion.section>

            {
                showEmailSuccess && <motion.div
                    initial={{ y: 50, opacity: 0 }} // Start below and invisible
                    animate={{ y: 0, opacity: 1 }} // Move up and become visible
                    exit={{ y: 50, opacity: 0 }} // Disappear smoothly
                    transition={{ duration: 0.7, ease: "easeOut" }} // Smooth timing
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    style={{
                        position: "fixed",  // Fix position relative to viewport
                        top: "50%",         // Center vertically
                        left: "40%",        // Center horizontally
                        transform: "translate(-30%, -50%)", //
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        height: "40px",
                        width: "300px",
                        backgroundColor: "#2daf00",
                        borderRadius: "8px",
                        boxShadow: "0px 16px 24px 0px rgba(0, 0, 0, 0.14)",
                        margin: "0 auto",
                        alignItems: "center",
                        padding: "10px",
                        zIndex: 1000
                    }}
                >

                    <p style={{
                        color: "#FFF",
                        fontFamily: '"Plus Jakarta Sans", sans-serif',
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "-0.5px"
                    }}>Email sent Successfully</p>
                </motion.div>
            }
            <footer style={{
                width: '100%',
                height: "400px",
                background: "#040404",
                position: "relative",
                display: "flex",
                flexDirection: 'column',
                padding: "80px 150px 50px 120px"
            }}>

                <div style={{
                    display: "flex",
                    flexDirection: 'row',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                    }}>
                        <img
                            src={DatalyzeLogo}
                        />
                        <img
                            onClick={() => {
                                window.open("https://analyticsindiamag.com/", "_blank")
                            }}
                            src={AIM_logo}
                            style={{
                                width: "120px",
                                alignSelf: "end",
                                marginTop: "5px",
                                cursor: "pointer"
                            }}
                        />
                    </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        marginLeft: "150px"
                    }}>
                        <p style={{
                            color: '#5C5C5E',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            textTransform: 'uppercase',
                            marginBottom: "22px"
                        }}>Explore</p>

                        <p
                            onClick={() => {
                                window.open("https://machinehack.com/", "_blank")
                            }}
                            style={{
                                color: 'rgba(255, 255, 255, 0.6)',
                                fontFamily: '"Plus Jakarta Sans"',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '34px',
                                letterSpacing: '-1px',
                                cursor: "pointer"
                            }}>About MachineHack
                        </p>
                        <p style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>About Datalyze</p>
                        <p style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Problem Statements</p>
                    </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        marginLeft: "150px"
                    }}>
                        <p style={{
                            color: '#5C5C5E',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            textTransform: 'uppercase',
                            marginBottom: "22px"
                        }}>Legal</p>

                        <p style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Terms of Service</p>
                        <p style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Privacy Policy</p>
                        <p style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Cancellation & Refund Policy</p>
                    </div>


                    <button
                        onClick={() => {
                            navigate("/contact-us")
                        }}
                        style={{
                            display: 'flex',
                            width: '218.565px',
                            height: '49.783px',
                            padding: '17.391px 34.783px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10.87px',
                            flexShrink: 0,
                            borderRadius: '50px',
                            background: 'linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)',
                            color: '#000',
                            textAlign: 'center',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '15.217px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '100%',
                            border: "none",
                            position: "absolute",
                            right: "100px",
                            top: "180px",
                            cursor: "pointer"
                        }}>
                        Contact Us
                    </button>
                </div>
                <div style={{
                    width: "100%",
                    height: "1px",
                    background: "#1B1B1B",
                    paddingLeft: "120px",
                    paddingRight: "80px",
                    marginTop: "50px",
                }}></div>
                <div style={{ width: "100%", height: "50px" }}></div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                }}>
                    <p style={{
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontFamily: '"Plus Jakarta Sans"',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '34px',
                        letterSpacing: '-1px',
                    }}>© Copyright Kruxonomy Consulting Pvt Ltd</p>


                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <img
                            onClick={() => {
                                window.open("https://www.instagram.com/machinehack/", "_blank")
                            }}
                            src={Intsagram_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                        <img
                            onClick={() => {
                                window.open("https://www.linkedin.com/company/machine-hack/", "_blank")
                            }}
                            src={Linkedin_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                        <img
                            onClick={() => {
                                window.open("https://x.com/JoinMachinehack", "_blank")
                            }}
                            src={X_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                    </div>

                    <div style={{
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontFamily: '"Plus Jakarta Sans"',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '34px',
                        letterSpacing: '-1px',
                    }}>
                        © 2025 All rights reserved
                    </div>
                </div>
            </footer>
        </div >
    )
}

export default ContactUs;
