import React from 'react';

const LogoutIcon = ({
    height,
    width,
}) => (
    <svg width={width ? width : "12"} height={height ? height : "12"} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3816 5.1166L8.90659 2.6416L8.20009 3.3506L10.3501 5.5006H3.30859V6.5006H10.3501L8.20009 8.6506L8.90709 9.3576L11.3821 6.8826C11.4982 6.76652 11.5903 6.62871 11.6531 6.47703C11.716 6.32535 11.7483 6.16278 11.7483 5.9986C11.7483 5.83442 11.716 5.67185 11.6531 5.52017C11.5903 5.36849 11.4982 5.23068 11.3821 5.1146L11.3816 5.1166Z" fill="#F9C003" />
        <path d="M5.238 10.5C5.238 10.6326 5.18532 10.7598 5.09155 10.8536C4.99779 10.9473 4.87061 11 4.738 11H1.5C1.36739 11 1.24021 10.9473 1.14645 10.8536C1.05268 10.7598 1 10.6326 1 10.5V1.5C1 1.36739 1.05268 1.24021 1.14645 1.14645C1.24021 1.05268 1.36739 1 1.5 1H4.738C4.87061 1 4.99779 1.05268 5.09155 1.14645C5.18532 1.24021 5.238 1.36739 5.238 1.5V4.1665H6.238V1.5C6.238 1.10218 6.07997 0.720644 5.79866 0.43934C5.51736 0.158035 5.13583 0 4.738 0L1.5 0C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5L0 10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H4.738C5.13583 12 5.51736 11.842 5.79866 11.5607C6.07997 11.2794 6.238 10.8978 6.238 10.5V7.8335H5.238V10.5Z" fill="#F9C003" />
    </svg>
);

export default LogoutIcon;