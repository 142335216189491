import { motion } from "framer-motion";
import React, { useState, useRef, useEffect } from "react";
import ExlLogo from "../assets/img/exl_logo.png"
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Avatar from "./Avatar";
import { useSelector } from "react-redux";
import "./GameList.css";
import "swiper/css/navigation";
import Introduction from "./Introduction";
import CustomTypography from "../mui/customTypography";
import CustomCard from "../mui/customCard";
import { Box, Grid, Skeleton } from "@mui/material";
import { useGetAllProblemsQuery, useGetLeaderboardQuery, useSendEmailMutation } from "../store/services/datalyzeProblems";
import Sidebar from "../mui/sidebar";
import CoinsCard from '../mui/CoinsCard';
import pollyAudio from '../assets/mp3/polly-audio.mp3'
import EmailModal from "./EmailEnquiry";

const GameList = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isLoadingSendingEmail, setIsLoadingSendingEmail] = useState(false);
    const jwt = useSelector((state) => state?.user?.jwt);
    const fullURL = window.location.href;
    let isExl = fullURL?.includes("exl");
    const userDetails = useSelector((state) => state?.user?.userDetails);
    const coinsCount = useSelector((state) => state?.user?.coinsCount);
    const [sendEmail] = useSendEmailMutation()
    const { data, isLoading } = useGetAllProblemsQuery({
        location: "app",
        token: jwt
    });

    const { data: leaderboardData } = useGetLeaderboardQuery({ token: jwt });
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.2, ease: "easeOut" },
        },
    };
    const navigate = useNavigate();
    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(true);
    const [speechEnabled, setSpeechEnabled] = useState(true);
    const speechRef = useRef(null);

    const [openEmail, setOpenEmail] = useState(false);

    const handleOpenEmail = () => setOpenEmail(true);
    const handleCloseEmail = () => setOpenEmail(false);

    const handleSendEmail = async (emailData) => {
        setIsLoadingSendingEmail(true);
        try {
            const result = await sendEmail(emailData);
            if (result?.data?.message === "success") {
                setIsLoadingSendingEmail(false);
                handleCloseEmail();
                setShowEmailSuccess(true);
                setTimeout(() => {
                    setShowEmailSuccess(false);
                }, 4000);
            } else {
                setIsLoadingSendingEmail(false);
                handleCloseEmail();
            }
        } catch (err) {
            setIsLoadingSendingEmail(false);
            handleCloseEmail();
        }
    };




    const setVoice = () => {
        if (isSpeaking) {
            // Pause the audio if it's already playing
            if (speechRef.current) {
                speechRef.current.pause();
                speechRef.current.currentTime = 0; // Reset the audio to the start
            }
            setIsSpeaking(false);
        } else if (speechEnabled) {
            // Play the hardcoded MP3 file
            const audio = new Audio(pollyAudio); // Replace with the correct path to your MP3 file
            speechRef.current = audio; // Save audio reference to manage state later
            audio.onplay = () => setIsSpeaking(true);
            audio.onended = () => setIsSpeaking(false);
            audio.play();
            setIsSpeaking(true);
        }

        setSpeechEnabled((prev) => !prev); // Toggle the speech-enabled state
    };

    useEffect(() => {
        if (data && leaderboardData) {
            setIsDataLoaded(true);
        }
    }, [isLoading, data, leaderboardData]);


    useEffect(() => {
        if (data && leaderboardData && setIsDataLoaded) {
            const audio = new Audio(pollyAudio); // Replace with the correct path to your MP3 file
            speechRef.current = audio; // Save audio reference to manage state later
            audio.onplay = () => setIsSpeaking(true);
            audio.onended = () => setIsSpeaking(false);
            audio.play();
            setIsSpeaking(true);
        }
        return () => {
            if (speechRef.current) {
                speechRef.current.pause();
                speechRef.current.currentTime = 0; // Reset audio position
            }
        };
    }, [data, leaderboardData]);

    useEffect(() => {
        const hasSeenModal = localStorage.getItem("hasSeenModal");

        if (!hasSeenModal) {
            const timer = setTimeout(() => {
                setModalOpen(true);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, []);

    const handleClose = () => {
        setModalOpen(false);
        localStorage.setItem("hasSeenModal", "true");
    };

    return (
        <>
            {!isDataLoaded ? (
                <div
                    style={{
                        width: "100%",
                        minHeight: "100vh",
                        height: "auto",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <CircularProgress variant="indeterminate" sx={{ color: "#00FF85" }} />

                    </div>
                </div>
            ) : (
                <div
                    style={{
                        width: "100%",
                        minHeight: "100vh",
                        height: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        position: "relative",
                    }}
                >
                    {isModalOpen && (
                        <div
                            className="intro-modal-overlay"
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <motion.div
                                className="intro-modal-content"
                                style={{
                                    maxWidth: "1000px",
                                    height: "500px",
                                    position: "relative",
                                }}
                                initial={{ y: "100%", opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: "100%", opacity: 0 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 300,
                                    damping: 30,
                                    duration: 0.5,
                                }}
                                onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
                            >
                                <button
                                    className="close-button"
                                    onClick={handleClose}
                                    aria-label="Close Modal"
                                >
                                    ×
                                </button>
                                <Introduction isModalOpen={isModalOpen} onClose={handleClose} />
                            </motion.div>
                        </div>
                    )}

                    <div
                        className="sticky-header"
                    >
                        <div style={{ margin: "0 auto", height: "50px" }}>
                        </div>
                    </div>


                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px 50px 20px 50px" }}>
                        <div style={{ flexDirection: "column" }}>
                            <div>
                                {isExl && <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", marginBottom: "20px", marginLeft: "10px" }}>
                                    <img src={ExlLogo} alt={"ExlLogo"} style={{ width: "130px", height: "40px", }} />
                                </div>}
                                <Sidebar
                                    userDetails={userDetails}
                                    leaderboardData={leaderboardData?.data}
                                    data={data?.data}

                                />
                                <CoinsCard coinsCount={coinsCount} />
                                {!isExl && <button
                                    style={{
                                        width: "100%",
                                        background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                                        color: "#000",
                                        fontSize: "15px",
                                        fontWeight: "700",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        marginTop: "25px",
                                        borderRadius: "15px",
                                        border: "none"
                                    }}
                                    onClick={() => {
                                        handleOpenEmail()
                                    }}>
                                    Contact Us
                                </button>}
                            </div>
                        </div>
                        <div style={{ paddingLeft: "60px", }}>
                            <Grid>
                                <CustomTypography
                                    sx={{
                                        fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.75rem" },
                                        color: "#FFF",
                                        fontFamily: "Plus Jakarta Sans",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        letterSpacing: "-0.5px",
                                    }}
                                >
                                    Problem Solving through data
                                </CustomTypography>
                                {isLoading ? (
                                    <Box style={{
                                        mt: 4,
                                        display: "flex",
                                        flexDirection: 'row',
                                        justifyContent: "start",
                                        flexWrap: "wrap",
                                        width: "100%",
                                        margin: "0 auto",
                                        paddingTop: "50px",
                                    }}>
                                        {[1, 2, 3, 4, 5, 6]?.map(() => {
                                            return <Skeleton
                                                variant="rectangular"
                                                width={250}
                                                height={118}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginRight: "15px",
                                                    flex: "0 1 250px",
                                                    marginBottom: "20px"
                                                }}
                                            />
                                        })}
                                    </Box>
                                ) : (
                                    <Box
                                        className="cards"
                                        sx={{
                                            mt: 4,
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "start",
                                            flexWrap: "wrap",
                                            width: "100%",
                                            margin: "0 auto",
                                            paddingTop: "50px",
                                        }}
                                    >
                                        {data?.data?.map((item, index) => {
                                            return (
                                                <Grid
                                                    key={index}
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={3} // Ensures 4 cards per row on medium screens
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        marginRight: "15px",
                                                        flex: "0 1 250px",
                                                        marginBottom: "20px",
                                                    }}
                                                >
                                                    <motion.div
                                                        initial="hidden"
                                                        animate="visible"
                                                        variants={cardVariants}
                                                        whileHover={{
                                                            scale: 1.08,
                                                            border: "1px solid yellow",
                                                            transition: { duration: 0.2, ease: "easeOut" },
                                                        }}
                                                        style={{
                                                            borderRadius: "12px",
                                                            position: "relative",
                                                            overflow: "hidden", // Ensures overlay stays inside the card
                                                        }}
                                                        onClick={() => {
                                                            if (isExl) {
                                                                navigate(`/gamified-data-analytics/${item?._id}`);
                                                            } else if ((index === 0)) {
                                                                navigate(`/gamified-data-analytics/${item?._id}`);
                                                            }
                                                        }}
                                                    >
                                                        {(index !== 0 && !isExl) && <div
                                                            className="paywall-overlay"
                                                        >
                                                            Subscribe to Unlock 🔒
                                                        </div>}
                                                        <CustomCard firstImage={item?.icon} cardText={item?.title} />
                                                    </motion.div>
                                                </Grid>
                                            )
                                        })}
                                    </Box>
                                )}
                            </Grid>
                        </div>
                    </div>
                    <EmailModal
                        isLoading={isLoadingSendingEmail}
                        open={openEmail}
                        handleClose={handleCloseEmail}
                        handleSendEmail={handleSendEmail}
                    />

                    <div onClick={setVoice}>
                        <Avatar isSpeaking={isSpeaking} onClick={setVoice} />
                    </div>

                    {showEmailSuccess && <motion.div
                        initial={{ y: 50, opacity: 0 }} // Start below and invisible
                        animate={{ y: 0, opacity: 1 }} // Move up and become visible
                        exit={{ y: 50, opacity: 0 }} // Disappear smoothly
                        transition={{ duration: 0.7, ease: "easeOut" }} // Smooth timing
                        style={{
                            position: "fixed",  // Fix position relative to viewport
                            top: "50%",         // Center vertically
                            left: "40%",        // Center horizontally
                            transform: "translate(-30%, -50%)", //
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            height: "40px",
                            width: "300px",
                            backgroundColor: "#2daf00",
                            borderRadius: "8px",
                            boxShadow: "0px 16px 24px 0px rgba(0, 0, 0, 0.14)",
                            margin: "0 auto",
                            alignItems: "center",
                            padding: "10px",
                            zIndex: 1000
                        }}
                    >

                        <p style={{
                            color: "#FFF",
                            fontFamily: '"Plus Jakarta Sans", sans-serif',
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "24px",
                            letterSpacing: "-0.5px"
                        }}>Email sent Successfully</p>
                    </motion.div>}
                </div >
            )}
        </>
    );
};
export default GameList;
