import React, { useState } from 'react';
import './CardCarousel.css';
import { motion } from 'framer-motion';
import InterfaceImg from "../../assets/img/interface.png";
import WorkflowImg from "../../assets/img/workflow.png";
import Access from "../../assets/img/access.png";

const features = [
    {
        img: InterfaceImg,
        title: "No-Code Interface",
        description: "Designed for simplicity, no technical skills needed.",
        delay: 0.2,
    },
    {
        img: WorkflowImg,
        title: "Guided Workflows",
        description: "Step-by-step assistance from data loading to analysis.",
        delay: 0.4,
    },
    {
        img: Access,
        title: "Accessible to All",
        description: "Affordable, beginner-friendly, and cloud-based.",
        delay: 0.6,
    },
];

const Card = ({ title, copy, img }) => {
    const [hovered, setHovered] = useState(false);

    const cardStyle = {
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
        width: '341px',
        height: "323px",
        textAlign: 'center',
        backgroundColor: "#040404",
        border: hovered ? "1px solid #D9D9D9" : "1px solid #868686",
        borderRadius: "20px",
        marginRight: "40px",
        alignItems: "center",
        justifyContent: "center",  // Ensure the image is centered
        transition: "all 0.3s ease",
    };

    return (
        <motion.div
            style={cardStyle}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', padding: '1rem' }}>
                {!hovered && <motion.img
                    src={img}
                    alt={copy}
                    style={{
                        width: "121px",
                        height: "121px",
                        transition: "transform 0.3s ease",
                    }}
                    animate={{
                        y: hovered ? -20 : 30  // Move the image upwards smoothly on hover
                    }}
                    transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                />}
                <motion.div
                    style={{ display: 'flex', flexDirection: "column", textAlign: "center", opacity: hovered ? 1 : 0 }}
                    animate={{ opacity: hovered ? 1 : 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <motion.h3
                        style={{
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '24px',
                            fontStyle: 'normal',
                            fontWeight: 700, // Using the default value 700 for font-weight
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            background: 'linear-gradient(135deg, #FFF 30%, #D5D8F6 80%, #FDF7FE 100%)',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: hovered ? 1 : 0, y: hovered ? 0 : 20 }}
                        transition={{ duration: 0.5 }}
                    >
                        {title}
                    </motion.h3>
                    <motion.p
                        style={{
                            color: '#868686',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '30px',
                            letterSpacing: '-0.5px',
                        }}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: hovered ? 1 : 0, y: hovered ? 0 : 20 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                    >
                        {copy}
                    </motion.p>
                </motion.div>
            </div>

        </motion.div>
    );
};

const CardCarousel = () => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "80px"
        }}>
            {features.map((card, index) => (
                <Card
                    key={index}
                    title={card.title}
                    copy={card.description}
                    img={card.img}
                />
            ))}
        </div>
    );
};

export default CardCarousel;
