import React from "react";
import { Button } from "@mui/material";

const BtnMain = ({ children, onClick, sx, ...props }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        height: "36px",
        width: "100%",
        // maxWidth: "120px",
        background: "linear-gradient(91deg, #00ff85 7.38%, #00e5ff 96.85%)",
        borderRadius: "12px",
        border: "none",
        color: "#000",
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "100%",
        marginRight: "15px",
        zIndex: 10,
        textTransform: "none",
        "&:hover": {
          background: "linear-gradient(91deg, #00e5ff 7.38%, #00ff85 96.85%)",
        },
        ...sx
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

const BtnMainLanding = ({ children, onClick, ...props }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        height: "52px",
        maxWidth: { xs: "130px", md: "200px" },
        background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
        borderRadius: "50px",
        border: "none",
        color: "#000",
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        fontSize: { xs: "13px", md: "15.217px" },
        width: { xs: "130px", md: "200px" },
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "100%",
        marginRight: "10px",
        zIndex: 10,
        textTransform: "none",
        "&:hover": {
          background: "linear-gradient(91deg, #00e5ff 7.38%, #00ff85 96.85%)",
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

const BtnSecondary = ({ children, onClick, ...props }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        height: "52px",
        maxWidth: { width: { xs: "130px", md: "200px" }, xs: "130px", md: "200px" },
        width: { xs: "130px", md: "200px" },
        gap: "10.87px",
        background: "var(--huly-io-woodsmoke, #090A0C)",
        border: "var(--stroke-weight-1, 1px) solid rgba(255, 255, 255, 0.30)",
        borderRadius: "999px",
        color: "#fff",
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        fontSize: { xs: "13px", md: "15.217px" },
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "100%",
        zIndex: 10,
        textTransform: "none", // Ensures text is not capitalized
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export { BtnMain, BtnSecondary, BtnMainLanding };
