import React, { useEffect } from "react";
import DataAnalysis from "./components/DataAnalysis";
import GameList from "./components/GameList";
import Gameplay from "./components/Gameplay";
import Login from "./components/Login";
import Introduction from "./components/Introduction";
import Landing from "./components/Landing";
import ContactUs from "./components/ContactUs";
import AboutUs from "./components/AboutUs";
import Terms from "./components/Footer/terms";
import Privacy from "./components/Footer/privacy";
import Cancellation from "./components/Footer/cancellation";
import { useSelector } from "react-redux";
import { useLazyGetUserDetailsQuery } from "./store/services/userLogin"
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import "./App.css";

function App() {
  const jwt = useSelector((state) => state?.user?.jwt);
  const [getUserDetailsQuery] = useLazyGetUserDetailsQuery();

  useEffect(() => {
    if (jwt && jwt !== "null") {
      getUserDetailsQuery({
        token: jwt
      });

    }
  }, [jwt])

  return (
    <div className="app-wrapper" style={{ width: '100%', backgroundColor: "#010C12" }}>
      <div className="App"
      >
        <Router>
          <Routes>
            <Route path="/login" element={jwt ? <Navigate to="/gamelist" /> : <Login />} />
            <Route path="/" element={<Landing />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/cancellation" element={<Cancellation />} />
            {!jwt ?
              <>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </> : <>
                <Route path="/introduction" element={<Introduction />} />
                <Route path="/gamelist" element={<GameList />} />
                <Route path="/gamified-data-analytics/:problemId" element={<Gameplay />} />
                <Route path="/gamified-data-analytics/:problemId/:customProblemSlug" element={<Gameplay />} />
                <Route path="/analysis" element={<DataAnalysis />} />
              </>
            }

          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;

