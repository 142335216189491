import React, { useState, useEffect } from "react";
import "./TypeJsEffect.css";

const TypeJsEffect = () => {
    const textToType = "No Expertise Needed!";
    const [displayText, setDisplayText] = useState("");
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index < textToType.length) {
            const typingSpeed = 130; // Adjust speed if needed
            const timer = setTimeout(() => {
                setDisplayText(textToType.substring(0, index + 1));
                setIndex(index + 1);
            }, typingSpeed);

            return () => clearTimeout(timer);
        }
    }, [index, textToType]);

    return (
        <div className="typeJsWrapper">
            <div className="container">
                <h1 className="typeJsText">{displayText}</h1>
            </div>
        </div>
    );
};

export default TypeJsEffect;
