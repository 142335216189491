import React from 'react';
import speech from "../assets/img/SpeechAvatar.svg";
import Lottie from "lottie-react";
import './Avatar.css';
import SpeechAnimations from "../assets/animations/Animation - 1731051083596.json"

const Avatar = ({ isSpeaking }) => {
  return (
    <div className={`avatar-container ${isSpeaking ? 'speaking' : ''}`}>
      <div style={{
        marginTop: "10px",
        width: "341px",
        height: "73px",
        borderRadius: "16px",
        background: "var(--Glass-Modal, rgba(26, 29, 33, 0.2))",
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        boxShadow: "16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset"
      }}>
        <img src={speech} style={{ marginRight: "10px" }} />
        {isSpeaking && <Lottie
          animationData={SpeechAnimations}
          loop={true}
          style={{
            height: "80px",
            width: "300px",
          }}
        />}
      </div>
    </div>
  );
}

export default Avatar;
