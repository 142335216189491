const findDuplicates = (array) => {
    const duplicates = [];
    const occurrences = new Map();
    // Count occurrences of each object
    array.forEach((obj) => {
        const objString = JSON.stringify(obj); // Convert object to a string
        occurrences.set(objString, (occurrences.get(objString) || 0) + 1);
    });
    // Add objects that appear more than once, including originals
    array.forEach((obj) => {
        const objString = JSON.stringify(obj);
        if (occurrences.get(objString) > 1) {
            duplicates.push(obj);
        }
    });

    return duplicates;
};

const fillMissingCategoryValue = (key, selectedMissingValue, tableData) => {
    if (selectedMissingValue?.[key] === "mode") {
        const valueCounts = {};
        // Count occurrences of each value for the given key
        for (const obj of tableData) {
            const value = obj[key];
            if (value !== undefined) {
                valueCounts[value] = (valueCounts[value] || 0) + 1;
            }
        }

        // Find the most occurring value
        let maxCount = 0;
        let mostOccurringValue = null;

        for (const [value, count] of Object.entries(valueCounts)) {
            if (count > maxCount) {
                maxCount = count;
                mostOccurringValue = value;
            }
        }

        return mostOccurringValue;
    }
}


const fillMissingNumericValue = (key, selectedMissingValue, tableData) => {
    if (selectedMissingValue?.[key] === "mean") {
        let sum = 0;
        let count = 0;
        for (const obj of tableData) {
            const value = parseFloat(obj[key]); // Convert the value to a number
            if (!isNaN(value)) {
                sum += value;
                count++;
            }
        }

        return count > 0 ? (sum / count).toFixed(2) : null;
    } else if (selectedMissingValue?.[key] === "median") {
        const numericValues = [];
        for (const obj of tableData) {
            const value = parseFloat(obj[key]); // Convert the value to a number
            if (!isNaN(value)) {
                numericValues.push(value);
            }
        }

        if (numericValues.length === 0) return null;
        numericValues.sort((a, b) => a - b);
        const mid = Math.floor(numericValues.length / 2);
        if (numericValues.length % 2 === 0) {
            return ((numericValues[mid - 1] + numericValues[mid]) / 2).toFixed(2); // Average of middle two numbers
        } else {
            return (numericValues[mid]).toFixed(2); // Middle value
        }
    }
}

const areArraysEqual = (arr1, arr2) => {
    // Check if lengths are equal
    if (arr1.length !== arr2.length) return false;
    // Check if every element matches
    return arr1.every((value) => arr2?.includes(value));
}

export { findDuplicates, fillMissingCategoryValue, fillMissingNumericValue, areArraysEqual };