import React, { useEffect, useState } from "react";
import DATALYZE from "../assets/img/dataLyze-new.svg";
import error from "../assets/img/error.svg";
import EXL from "../assets/img/exl_logo.png";
import Email from "../assets/img/email.svg";
import Password from "../assets/img/password.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  useLoginUserMutation,
  useAuthAttemptMutation,
  useLoginUserOTPMutation,
  useUpdateUserMutation
} from "../store/services/userLogin";
import { setJwtToken } from "../store/slices";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import pako from "pako"; // For DEFLATE compression
import { encode as base64Encode, decode as base64Decode } from "base-64";
import { motion } from "framer-motion";
import "./Login.css";


const Login = () => {
  const tenantID = process.env.REACT_APP_TENANT_ID;
  const callback_url = process.env.REACT_APP_USER_ENDPOINT + "sso/azure/callback";
  const issuer = process.env.REACT_APP_DOMAIN_NAME;
  const fullURL = window.location.href;
  let isExl = fullURL?.includes("exl");

  const getToken = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("token"); // Extract token
  };
  const getError = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("error"); // Extract token
  };

  const dispatch = useDispatch();
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const [otpLoginUser, { isLoading: isVerifyingOtp, }] = useLoginUserOTPMutation();
  const [userAuthAttempt, { isLoading: isLoadingAuthAttempt }] = useAuthAttemptMutation();
  const [updateUser, { isLoading: isLoadingUserUpdate }] = useUpdateUserMutation();

  const navigate = useNavigate();

  // const API_URL =
  //   "https://murhsmozuf.execute-api.ap-south-1.amazonaws.com/dev/signin";
  const [apiError, setApiError] = useState("");
  const [status, setStatus] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [localJWT, setLocalJWT] = useState("");
  const [ssoError, setSsoError] = useState(null);

  useEffect(() => {
    let jwt = getToken();
    let error = getError();
    if (jwt && !error && isExl) {
      dispatch(setJwtToken({ token: jwt }));
      navigate("/gamelist");
    } else if (error && isExl) {
      const decodedError = base64Decode(error)
      setSsoError(decodedError)
      setTimeout(() => {
        setSsoError(null)
      }, [5000])
    }
  }, [])


  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: status === "password" ? Yup.string().required("Password is required").min(8, "Password must be at least 8 characters long") : Yup.string(),
      name: showForm ? Yup.string().required("Name is required") : Yup.string(),

    }),
    onSubmit: (values) => {
      if (status === "password") {
        handleLoginUsingPassword(values);
      } else if (status === "otp") {
        handleLoginUsingOTP(values);
      } else if (showForm) {
        handleUpdateProfile(values)
      } else {
        handleAuthAttempt(values);
      }
    }
  });

  const generateSAML = () => {
    const samlRequestXML = `<samlp:AuthnRequest xmlns="urn:oasis:names:tc:SAML:2.0:metadata" ID="F84D888AA3B44C1B844375A4E8210D9E" Version="2.0" IssueInstant="${new Date().toISOString()}" IsPassive="false" AssertionConsumerServiceURL="${callback_url}" xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol" ForceAuthn="false">
    <Issuer xmlns="urn:oasis:names:tc:SAML:2.0:assertion">${issuer}</Issuer>
</samlp:AuthnRequest>`;

    const compressedRequest = pako.deflateRaw(samlRequestXML, { level: 0 });
    const base64Request = base64Encode(String.fromCharCode(...compressedRequest));
    const encodedRequest = encodeURIComponent(base64Request);
    return encodedRequest;
  }

  const handleAuthAttempt = async (data) => {
    try {
      const result = await userAuthAttempt({
        email: data?.email
      });
      if (result?.data?.data?.passwordEnabled) {
        setStatus("password");
      } else {
        setStatus("otp");
      }
      setShowForm(false)
    } catch (err) {
      setApiError("Invalid credentials or tenant ID");
    }
  }

  const handleLoginUsingPassword = async (data) => {
    try {
      const result = await loginUser({ body: data });
      if (result.error) {
        throw new Error(result.error.data.message || "Login failed");
      }
      if (result?.data?.data?.token) {
        setStatus(null);
        dispatch(setJwtToken({ token: result?.data?.data?.token }));
        navigate("/gamelist");
      }
      setShowForm(false)
    } catch (err) {
      setApiError(err.message);
    }
  }

  const handleLoginUsingOTP = async (data) => {
    delete data.password;
    try {
      const result = await otpLoginUser({
        body: data,
        domain: window.location.hostname
      });
      if (result.error) {
        throw new Error(result.error.data.message || "OTP verification failed");
      }
      if (result?.data?.data?.token) {
        if (result?.data?.data?.hasRegistered) {
          setStatus(null);
          setShowForm(true);
          setLocalJWT(result?.data?.data?.token);
          return;
        } else {
          dispatch(setJwtToken({ token: result?.data?.data?.token }));
          navigate("/gamelist");
          setStatus(null);
        }
      }
      setShowForm(false)
    } catch (err) {
      setApiError(err.message);
    }
  }

  const handleUpdateProfile = async (data) => {
    try {
      const result = await updateUser(
        {
          body: { name: data?.name },
          token: localJWT
        });
      if (result?.data?.data?._id) {
        dispatch(setJwtToken({ token: localJWT }));
        navigate("/gamelist");
      }
    } catch (err) {
      setApiError("Somethin went wrong");
    }
  }

  const openLoginPopup = () => {
    window.open(
      `https://login.microsoftonline.com/${tenantID}/saml2?SAMLRequest=${generateSAML()}`,
      "_blank",
      // "width=500,height=600"
    );
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <div className="login-form-overlay"></div>
        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center" }}>
          <img
            src={DATALYZE}
            alt="Datalyze Logo"
            style={{ maxWidth: "100%", height: "35px", cursor: "pointer" }}
            onClick={() => {
              navigate('/')
            }}
          />
          {isExl && <img src={EXL} className="title" alt="EXL Logo" style={{ width: "70px", height: "30px", marginLeft: "20px" }} />}
        </div>
        <p className="description">
          A Simulation-Based Gamified Learning Platform for Problem Solving with Data
        </p>
        {isExl ?
          <div
            style={{
              width: "410px",
              height: "364px",
              backgroundColor: "#010C12",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              // border: "0.3px solid grey"
            }}>
            <h4 style={{
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "25px",
              letterSpacing: "0.15px",
              background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>
              Your Organization has enabled SSO
            </h4>
            <p style={{
              color: "#9B9C9E", // Replace CSS variable with actual color
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.15px",
              marginTop: "20px"
            }}>Your admin has enabled SSO (Single Sign-On).
              In Order to access your account, you will need to login in with your SSO company credentials.</p>
            <button
              className="submit-btn"
              style={{ width: "400px", border: "none" }}
              type="submit"
              onClick={() => {
                openLoginPopup()
              }}
            >
              Continue
            </button>
          </div>
          :

          <form onSubmit={formik.handleSubmit} style={{ minHeight: "300px" }}>
            {!showForm && !status &&
              <div className="input-container" style={{ marginTop: "10px", marginBottom: "25px" }}>
                <input
                  className="email"
                  type="text"
                  placeholder="Enter your email"
                  {...formik.getFieldProps("email")}
                />
                <img src={Email} className="email-icon" alt="Email Icon" />
                {formik.touched.email && formik.errors.email ? (
                  <p className="error-message">{formik.errors.email}</p>
                ) : null}
              </div>}

            {status === "password" && !showForm &&
              <div className="input-container" style={{ marginBottom: "25px" }}>
                <input
                  className="email"
                  type="password"
                  placeholder="Password"
                  {...formik.getFieldProps("password")}
                />
                <img src={Password} className="email-icon" alt="Password Icon" />
                {formik.touched.password && formik.errors.password ? (
                  <p className="error-message">{formik.errors.password}</p>
                ) : null}
              </div>}
            {status === "otp" && !showForm &&
              <div className="input-container" style={{ marginBottom: "25px" }}>
                <input
                  className="email"
                  type="password"
                  placeholder="Enter OTP"
                  {...formik.getFieldProps("otp")}
                />
                <img src={Password} className="email-icon" alt="Password Icon" />
                {formik.touched.password && formik.errors.password ? (
                  <p className="error-message">{formik.errors.password}</p>
                ) : null}
              </div>}


            {showForm && !status && <div className="input-container" style={{ marginBottom: "25px" }}>
              <input
                className="name"
                type="text"
                placeholder="Enter your name"
                {...formik.getFieldProps("name")}
                style={{ marginBottom: "20px" }}
              />
              {formik.touched.name && formik.errors.name ? (
                <p className="error-message">{formik.errors.name}</p>
              ) : null}
            </div>}


            <button className="submit-btn" type="submit">
              {isLoading || isLoadingAuthAttempt || isVerifyingOtp || isLoadingUserUpdate ?
                <CircularProgress size={24} />
                : "Continue"
              }
            </button>
            {apiError && <p className="error-message">{apiError}</p>}
          </form>}
        {ssoError && <motion.div
          initial={{ y: 50, opacity: 0 }} // Start below and invisible
          animate={{ y: 0, opacity: 1 }} // Move up and become visible
          exit={{ y: 50, opacity: 0 }} // Disappear smoothly
          transition={{ duration: 0.7, ease: "easeOut" }} // Smooth timing
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            height: "40px",
            width: "auto",
            backgroundColor: "rgba(74, 18, 20, 0.70)",
            borderRadius: "8px",
            boxShadow: "0px 16px 24px 0px rgba(0, 0, 0, 0.14)",
            margin: "0 auto",
            alignItems: "center",
            padding: "10px",
            zIndex: 1000
          }}
        >
          <img src={error} style={{ marginRight: "5px" }} />
          <p style={{
            color: "#FFF",
            fontFamily: '"Plus Jakarta Sans", sans-serif',
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "-0.5px"
          }}>{ssoError}</p>
        </motion.div>}
      </div>
      <div className="login-image-contianer"></div>
    </div>
  );
};

export default Login;