import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logoutUser, setUserDetails, setCoinsCount } from "../slices";
const userEndpoint = process.env.REACT_APP_USER_ENDPOINT;

export const userAuthApis = createApi({
    reducerPath: "userAuthApis",
    baseQuery: fetchBaseQuery({
        baseUrl: userEndpoint,
    }),
    refetchOnFocus: true,
    endpoints: (builder) => ({
        authAttempt: builder.mutation({
            query: (newPost) => ({
                url: "auth-attempt",
                method: "POST",
                body: newPost,
            }),
        }),
        loginUser: builder.mutation({
            query: (newPost) => ({
                url: "signin",
                method: "POST",
                body: newPost.body,
            }),
        }),
        loginUserOTP: builder.mutation({
            query: (newPost) => ({
                url: `auth-verify?platform=${newPost?.domain}`,
                method: "POST",
                body: newPost.body,
            }),
        }),
        signupUser: builder.mutation({
            query: (newPost) => ({
                url: "signup",
                method: "POST",
                body: newPost.body,
            }),
        }),
        updateUser: builder.mutation({
            query: (newPost) => ({
                url: "update-user",
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${newPost?.token}`,
                },
                body: newPost.body,
            }),
        }),
        getUserDetails: builder.query({
            query: (payload) => ({
                url: `fetch-user`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${payload?.token}`,
                },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled;
                    if (result?.data?.data) {
                        dispatch(setUserDetails(result.data.data));
                        dispatch(setCoinsCount({ coinsCount: result.data.data?.totalScore }))
                    }
                } catch (error) {
                    if (error?.error?.status === 401) {
                        dispatch(logoutUser());
                    }
                }
            },
        }),
    }),

});

export const {
    useLoginUserMutation,
    useLazyGetUserDetailsQuery,
    useSignupUserMutation,
    useAuthAttemptMutation,
    useLoginUserOTPMutation,
    useUpdateUserMutation
} = userAuthApis;