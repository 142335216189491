import React, { useState, useRef, useEffect } from 'react'
import InterfaceImg from "../assets/img/interface.png"
import WorkflowImg from "../assets/img/workflow.png"
import Access from "../assets/img/access.png"
import Hero from "../assets/img/home-dt.png"
import AIM_logo from "../assets/img/aim_branding.svg"
import DatalyzeLogo from "../assets/img/dataLyze-new.svg"
import Intsagram_grey from "../assets/img/ig_grey.svg"
import Linkedin_grey from "../assets/img/linkedin_grey.svg"
import X_grey from "../assets/img/x_grey.svg"
import Gardient_Rectangle from "../assets/img/gardient_rectangle.svg"
import Checked_Bg from "../assets/img/checked_bg.svg"
import "./Landing.css"
import { BtnSecondary, BtnMainLanding } from '../mui/customButton';
import CustomTypography from '../mui/customTypography'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion';
// import bgImage from "../assets/img/Rectangle.png"

// @Animations
import MagnetLines from './Animations/MagnetLines'
import VariableProximity from './Animations/VariableProximity'
import TextScrollEffect from './Animations/TextScrollEffect'
// import InfiniteScroll from './Animations/InfiniteScroll'
import VideoCarousel from './Animations/VideoCarousel'
import CardCarousel from './Animations/CardCarousel'
import Particles from './Animations/Particles'
import TypeJsEffect from './Animations/TypeJsEffect'
import ScrollSnap from './Animations/ScrollSnap'

const Landing = () => {
    const fullURL = window.location.href;
    let isExl = fullURL?.includes("exl");
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => setIsLoaded(true), 300); // Delay to allow smooth transition
    }, []);

    const containerRef = useRef(null);

    const navigate = useNavigate();
    const targetUsers = [
        {
            title: "Beginners in Data Analysis",
            description: "Learn the basics of working with data in an intuitive way.",
            image: "https://machinehack-be.s3.ap-south-1.amazonaws.com/assests/datalyze_assets/beginners_in_data_science.png"
        },
        {
            title: "Non-Technical Professionals",
            description: "Explore data insights without relying on IT teams or technical tools.",
            image: "https://machinehack-be.s3.ap-south-1.amazonaws.com/assests/datalyze_assets/non_tech.png"
        },
        {
            title: "Students",
            description: "Get a head start on data skills with hands-on guidance.",
            image: "https://machinehack-be.s3.ap-south-1.amazonaws.com/assests/datalyze_assets/students.png"
        },
        {
            title: "Small Business Owners",
            description: "Make data-driven decisions for your business without any prior expertise.",
            image: "https://machinehack-be.s3.ap-south-1.amazonaws.com/assests/datalyze_assets/small_bussiness_owners.png"
        }
    ]

    const features = [
        {
            img: InterfaceImg,
            title: "No-Code Interface",
            description: "Designed for simplicity, no technical skills needed.",
            delay: 0.2,
        },
        {
            img: WorkflowImg,
            title: "Guided Workflows",
            description: "Step-by-step assistance from data loading to analysis.",
            delay: 0.4,
        },
        {
            img: Access,
            title: "Accessible to All",
            description: "Affordable, beginner-friendly, and cloud-based.",
            delay: 0.6,
        },
    ];

    return (
        <div className='landing-wrapper'>
            <div style={{ width: '100%', height: '980px', position: 'relative', zIndex: 0 }}>

                <iframe
                    src="https://player.vimeo.com/video/1060746861?autoplay=1&muted=1&loop=1&badge=0&autopause=0&player_id=0&app_id=58479&controls=0"
                    frameBorder="0"
                    allow="autoplay"
                    style={{
                        position: "absolute",
                        top: "-150px",
                        left: 0,
                        width: "1440px",
                        height: "980px",
                        border: "none",
                        opacity: isLoaded ? 1 : 0,
                        transition: "opacity 3s ease-in",
                    }}
                    title="Datalyze Promo Video"
                />
                <div style={{
                    height: "100px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingLeft: "70px",
                    paddingRight: "70px",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    backgroundColor: "transparent"
                }}>
                    <img
                        src={DatalyzeLogo} alt={"machinehack"}
                        style={{
                            width: "174px",
                            height: "40px",
                            cursor: 'pointer',
                            marginTop: "10px"
                        }}
                        onClick={() => {
                            navigate("/")
                        }}
                    />

                    <div style={{ display: "flex", flexDirection: "row", marginTop: "15px" }}>
                        <button
                            style={{
                                width: "72px",
                                height: "32px",
                                borderRadius: "50px",
                                background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                                color: "#040404",
                                fontFamily: "Plus Jakarta Sans",
                                fontSize: "11px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "16.5px", /* 150% */
                                letterSpacing: "-0.11px",
                                textTransform: "uppercase",
                                border: "none",
                                marginRight: "15px",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                navigate("/login")
                            }}
                        >
                            SIGN IN
                        </button>

                        <button style={{
                            width: "102px",
                            height: "32px",
                            borderRadius: "9999px",
                            background: "var(--huly-io-woodsmoke, #090A0C)",
                            border: "var(--stroke-weight-1, 1px) solid rgba(255, 255, 255, 0.30)",
                            color: "#FFF", // fallback for var(--huly-io-white)
                            fontFamily: "Plus Jakarta Sans",
                            fontSize: "11px", // fallback for var(--font-size-11)
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "16.5px", // fallback for var(--line-height-16_5)
                            letterSpacing: "-0.11px", // fallback for var(--letter-spacing--0_11)
                            textTransform: "uppercase",
                            cursor: "pointer",
                        }}
                            onClick={() => {
                                navigate("/contact-us")
                            }}
                        >
                            CONTACT US
                        </button>
                    </div>

                </div>



                <div style={{
                    width: "1440px",
                    background: "linear-gradient(180deg, #000 73.43%, rgba(0, 0, 0, 0.00) 91.53%)",
                    position: "absolute",
                    top: 0
                }}>

                </div>
                <div
                    ref={containerRef}
                    style={{
                        position: 'relative',
                        margin: "0 auto",
                        textAlign: "center",
                        marginTop: "100px"
                    }}
                >
                    <motion.div
                        initial={{ opacity: 0, y: 50 }} // Start invisible and 50px below
                        animate={{ opacity: 1, y: 0 }} // Move to normal position
                        transition={{ duration: 0.8, ease: "easeOut" }} // Smooth transition
                        style={{ padding: "0 !important", margin: "0 !important" }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >

                        <h2 style={{
                            textAlign: "center",
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: "64px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "68px",
                            letterSpacing: "-2.5px",
                            color: "#fff",
                            textShadow: "0 4px 6px rgba(23, 23, 23, 0.9), 0 8px 20px rgba(23, 23, 23, 0.9)"

                        }}>Simulation-Based</h2>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }} // Start invisible and 50px below
                        animate={{ opacity: 1, y: 0 }} // Move to normal position
                        transition={{ duration: 0.8, ease: "easeOut" }} // Smooth transition
                        style={{ padding: "0 !important", margin: "0 !important" }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >

                        <h2 style={{
                            textAlign: "center",
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: "50px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "68px",
                            letterSpacing: "-2.5px",
                            color: "#fff",
                            textShadow: "0 4px 6px rgba(23, 23, 23, 0.9), 0 8px 20px rgba(23, 23, 23, 0.9)"

                        }}>Gamified Learning for Data Analytics</h2>
                    </motion.div>
                    {/* <GradientText
                        colors={["#00FF85", "#00E5FF", "#00FF85", "#00E5FF", "#00FF85"]}
                        animationSpeed={3}
                        showBorder={false}
                        className="custom-class"
                    >
                        No Expertise Needed!
                    </GradientText> */}

                    <TypeJsEffect />
                </div>
                <div className='action-btn-wrapper-landing' style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                    <motion.div
                        initial={{ x: -200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 1,
                            // delay: 1,
                        }}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >
                        <BtnMainLanding onClick={() => {
                            navigate("/login")
                        }}>
                            Get Started
                        </BtnMainLanding>
                    </motion.div>


                    <motion.div
                        className="btn-secondary"
                        initial={{ x: 200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 1,
                            // delay: 1,
                        }}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >
                        <BtnSecondary onClick={() => {
                            if (!isExl) {
                                navigate("/contact-us")
                            } else {
                                navigate("/gamelist")
                            }
                        }}>
                            {isExl ? "See How It Works" : "Talk To Us"}
                        </BtnSecondary>
                    </motion.div>
                </div>
                <img
                    src={Hero}
                    alt="Hero"
                    style={{
                        position: "absolute",
                        top: "82%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 10,
                        width: "943px",
                        height: "530px",
                    }}
                />
            </div>


            <section
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "70px",
                    paddingTop: "70px",
                    paddingBottom: "70px",
                    position: "relative",
                    backgroundImage: `url(${Gardient_Rectangle})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    transition: "background 0.4s ease-in",
                }}
            >
                <motion.div
                    initial={{ opacity: 0, y: 50 }} // Start invisible and 50px below
                    animate={{ opacity: 1, y: 0 }} // Move to normal position
                    transition={{ duration: 0.8, ease: "easeOut" }} // Smooth transition
                    style={{ padding: "0 !important", margin: "0 !important" }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                >
                    <VariableProximity
                        label={'How it works'}
                        className={'variable-proximity-demo'}
                        fromFontVariationSettings="'wght' 600, 'opsz' 9"
                        toFontVariationSettings="'wght' 1000, 'opsz' 40"
                        containerRef={containerRef}
                        radius={100}
                        falloff='linear'
                        style={{ fontSize: "64px" }}
                    />
                </motion.div>

                <CustomTypography
                    sx={{
                        color: "#868686",
                        textAlign: "center",
                        fontFamily: "'Plus Jakarta Sans', sans-serif",
                        fontSize: { xs: "1rem", md: "18px" },
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        maxWidth: "663px",
                        margin: "0 auto",
                        marginTop: "25px"
                    }}
                >
                    Datalyze is an AI-powered learning platform with simulated analytics use cases, making data exploration, cleaning, and analysis seamless for everyone
                </CustomTypography>

                <motion.div
                    initial={{ width: "200px", height: "150px" }}
                    animate={{
                        width: "1000px",
                        height: "607px",
                    }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    style={{
                        marginTop: "30px",
                        padding: "15px",
                        borderRadius: "35px"
                    }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                >
                    <iframe
                        src="https://player.vimeo.com/video/1053295072?autoplay=1&muted=1&loop=1&badge=0&autopause=0&player_id=0&app_id=58479&controls=0"
                        frameBorder="0"
                        allow="autoplay"
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                        title="Datalyze Promo Video"
                    />
                </motion.div>
                <CustomTypography
                    sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontFamily: "'Plus Jakarta Sans', sans-serif",
                        fontSize: { xs: "1rem", md: "44px" },
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        margin: "0 auto",
                        marginTop: "25px"
                    }}
                >
                    Your Data Journey Starts Here
                </CustomTypography>
            </section>


            <section
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    padding: "100px 140px 0px 140px"
                }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "30%",
                    marginRight: "50px"
                }}>
                    <CustomTypography
                        sx={{
                            width: "400px",
                            color: "#fff",
                            fontFamily: "'Plus Jakarta Sans', sans-serif",
                            fontSize: { xs: "1rem", md: "64px" },
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "68px",
                            letterSpacing: "-3.36px",
                            textAlign: "left"
                        }}
                    >
                        What is datalyze?
                    </CustomTypography>
                    <MagnetLines
                        rows={12}
                        columns={12}
                        lineColor="#12B981"
                        lineWidth="0.3vmin"   // Reduce thickness here
                        lineHeight="2.4vmin"
                        baseAngle={-10}
                        className=""
                        style={{}}
                    />
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1
                }}>
                    <TextScrollEffect text={"Datalyze is a user-friendly platform that simplifies working with data. From loading raw data to"} />
                    <motion.ul
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        style={{ listStyle: "none", padding: 0 }}
                    >
                        <motion.li
                            style={{
                                paddingBottom: "15px",
                                borderBottom: "1px solid #fff",
                                color: "#FFF",
                                fontFamily: "Plus Jakarta Sans",
                                fontSize: "29px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "68px",
                                letterSpacing: "-2px"
                            }}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ duration: 1, delay: 0.5 }}
                        >
                            Deriving actionable insights
                        </motion.li>
                        <motion.li
                            style={{
                                paddingBottom: "15px",
                                borderBottom: "1px solid #fff",
                                color: "#FFF",
                                fontFamily: "Plus Jakarta Sans",
                                fontSize: "29px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "68px",
                                letterSpacing: "-2px"
                            }}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ duration: 1, delay: 1 }}
                        >
                            We’ve got you covered—no coding
                        </motion.li>
                        <motion.li
                            style={{
                                color: "#FFF",
                                fontFamily: "Plus Jakarta Sans",
                                fontSize: "29px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "68px",
                                letterSpacing: "-2px"
                            }}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ duration: 1, delay: 1.5 }}
                        >
                            No technical jargon.
                        </motion.li>
                    </motion.ul>
                </div>
            </section >

            <section style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
                marginTop: "100px",
                position: "relative",
                zIndex: "99",
                height: "400px",
                marginBottom: "280px"
            }}>

                <div style={{
                    height: '600px',
                    positon: 'relative',
                    width: "100%",
                    backgroundColor: "#040404",
                    textAlign: "center",
                    marginBottom: "30px",
                    paddingTop: "50px",
                }}>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }} // Start invisible and 50px below
                        animate={{ opacity: 1, y: 0 }} // Move to normal position
                        transition={{ duration: 0.8, ease: "easeOut" }} // Smooth transition
                        style={{ padding: "0 !important", margin: "0 !important" }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >
                        <VariableProximity
                            label={'Who is it for?'}
                            className={'variable-proximity-demo'}
                            fromFontVariationSettings="'wght' 600, 'opsz' 9"
                            toFontVariationSettings="'wght' 1000, 'opsz' 40"
                            containerRef={containerRef}
                            radius={100}
                            falloff='linear'
                            style={{
                                fontSize: "64px",
                                zIndex: 9999,
                                margin: "0 auto",
                                marginTop: "60px",
                                marginBottom: "50px",
                            }}
                        />
                    </motion.div>
                    <div style={{ width: "100%", height: "30px" }}></div>
                    <ScrollSnap targetUsers={targetUsers} />
                    <div style={{ width: "100%", height: "60px" }}></div>
                </div>
            </section>


            <section style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                paddingBottom: "70px"
            }}>


                <motion.div
                    initial={{ opacity: 0, y: 50 }} // Start invisible and 50px below
                    animate={{ opacity: 1, y: 0 }} // Move to normal position
                    transition={{ duration: 0.8, ease: "easeOut" }} // Smooth transition
                    style={{ padding: "0 !important", margin: "0 !important" }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                >

                    <VariableProximity
                        label={'What can  you  learn?'}
                        className={'variable-proximity-demo'}
                        fromFontVariationSettings="'wght' 600, 'opsz' 9"
                        toFontVariationSettings="'wght' 1000, 'opsz' 40"
                        containerRef={containerRef}
                        radius={100}
                        falloff='linear'
                        style={{
                            fontSize: "64px",
                            zIndex: "999",
                            margin: "0 auto",
                            marginTop: "120px",
                        }}
                    />
                </motion.div>
                <VideoCarousel />
            </section >


            <section style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "60px",
                background: "#040404",
                transition: 'all 0.4s ease-in',
                padding: "30px 0px 60px 0px",
                position: "relative"
            }}>
                <img
                    src={Checked_Bg}
                    style={{
                        position: "absolute",
                        top: "-140px",
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: "1"
                    }} />
                <motion.div
                    initial={{ opacity: 0, y: 50 }} // Start invisible and 50px below
                    animate={{ opacity: 1, y: 0 }} // Move to normal position
                    transition={{ duration: 0.8, ease: "easeOut" }} // Smooth transition
                    style={{ padding: "0 !important", margin: "0 !important", zIndex: 99999 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                >

                    <VariableProximity
                        label={'Why Datalyze?'}
                        className={'variable-proximity-demo'}
                        fromFontVariationSettings="'wght' 600, 'opsz' 9"
                        toFontVariationSettings="'wght' 1000, 'opsz' 40"
                        containerRef={containerRef}
                        radius={100}
                        falloff='linear'
                        style={{
                            fontSize: "64px",
                            margin: "0 auto",
                            marginTop: "120px",
                            marginBottom: "50px",
                        }}
                    />
                </motion.div>

                <div style={{ display: "flex", flexDirection: "row", marginTop: "40px", zIndex: 999 }}>
                    <CardCarousel features={features} />
                </div>
            </section>

            <section style={{
                width: "100%",
                height: "597px",
                position: 'relative',
                overflow: 'hidden',
                marginTop: "50px"
            }}>
                <Particles
                    particleColors={['#ffffff', '#ffffff']}
                    particleCount={300}
                    particleSpread={10}
                    speed={0.4}
                    particleBaseSize={160}
                    moveParticlesOnHover={true}
                    alphaParticles={false}
                    disableRotation={false}
                />

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                }}>

                    <p
                        style={{
                            textAlign: "center",
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: "34px",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            background: "linear-gradient(91deg, #E3E3E3 14.1%, #5F6563 96.17%)",
                            backgroundClip: "text",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}
                    >Ready to Datalyze?</p>

                    <p
                        style={{
                            textAlign: "center",
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: "34px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                            background: "linear-gradient(91deg, #01FE87 7.61%, #01E6F9 76.3%)",
                            backgroundClip: "text",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            marginTop: "20px"
                        }}
                    >
                        Unlock your data’s potential today.
                    </p>

                    <button
                        style={{
                            display: "inline-flex",
                            padding: "17.391px 34.783px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10.87px",
                            borderRadius: "4px",
                            background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                            color: "#000",
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: "15.217px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "100%",
                            border: "none",
                            marginTop: "20px",
                            cursor: "pointer",
                            zIndex: "99999"
                        }}
                        onClick={() => {
                            navigate("/login")
                        }}
                    >
                        Start Analyzing Now!
                    </button>
                </div>
            </section>

            <footer style={{
                width: '100%',
                height: "400px",
                background: "#040404",
                position: "relative",
                display: "flex",
                flexDirection: 'column',
                padding: "80px 150px 50px 120px"
            }}>

                <div style={{
                    display: "flex",
                    flexDirection: 'row',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                    }}>
                        <img
                            src={DatalyzeLogo}
                        />
                        <img
                            onClick={() => {
                                window.open("https://analyticsindiamag.com/", "_blank")
                            }}
                            src={AIM_logo}
                            style={{
                                width: "120px",
                                alignSelf: "end",
                                marginTop: "5px",
                                cursor: "pointer"
                            }}
                        />
                    </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        marginLeft: "150px"
                    }}>
                        <p style={{
                            color: '#5C5C5E',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            textTransform: 'uppercase',
                            marginBottom: "22px"
                        }}>Explore</p>

                        <p
                            onClick={() => {
                                window.open("https://machinehack.com/", "_blank")
                            }}
                            style={{
                                color: 'rgba(255, 255, 255, 0.6)',
                                fontFamily: '"Plus Jakarta Sans"',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '34px',
                                letterSpacing: '-1px',
                                cursor: "pointer"
                            }}>About MachineHack
                        </p>
                        <p onClick={() => {
                            window.open("https://machinehack.com/", "_blank")
                        }} style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>About Datalyze</p>
                        <p style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Problem Statements</p>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        marginLeft: "150px"
                    }}>
                        <p style={{
                            color: '#5C5C5E',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            textTransform: 'uppercase',
                            marginBottom: "22px"
                        }}>Legal</p>

                        <a onClick={() => {
                            navigate("/terms")
                        }} style={{
                            background: "black",
                            border: "none",
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Terms of Service</a>
                        <a onClick={() => {
                            navigate("/privacy-policy")
                        }} style={{
                            background: "black",
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer",
                        }}>Privacy Policy</a>
                        <a onClick={() => {
                            navigate("/cancellation")
                        }} style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Cancellation & Refund Policy</a>
                    </div>


                    <button
                        onClick={() => {
                            navigate("/contact-us")
                        }}
                        style={{
                            display: 'flex',
                            width: '218.565px',
                            height: '49.783px',
                            padding: '17.391px 34.783px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10.87px',
                            flexShrink: 0,
                            borderRadius: '50px',
                            background: 'linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)',
                            color: '#000',
                            textAlign: 'center',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '15.217px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '100%',
                            border: "none",
                            position: "absolute",
                            right: "100px",
                            top: "180px",
                            cursor: "pointer"
                        }}>
                        Contact Us
                    </button>
                </div>
                <div style={{
                    width: "100%",
                    height: "1px",
                    background: "#1B1B1B",
                    paddingLeft: "120px",
                    paddingRight: "80px",
                    marginTop: "50px",
                }}></div>
                <div style={{ width: "100%", height: "50px" }}></div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                }}>
                    <p style={{
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontFamily: '"Plus Jakarta Sans"',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '34px',
                        letterSpacing: '-1px',
                    }}>© Copyright Kruxonomy Consulting Pvt Ltd</p>


                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <img
                            onClick={() => {
                                window.open("https://www.instagram.com/machinehack/", "_blank")
                            }}
                            src={Intsagram_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                        <img
                            onClick={() => {
                                window.open("https://www.linkedin.com/company/machine-hack/", "_blank")
                            }}
                            src={Linkedin_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                        <img
                            onClick={() => {
                                window.open("https://x.com/JoinMachinehack", "_blank")
                            }}
                            src={X_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                    </div>

                    <div style={{
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontFamily: '"Plus Jakarta Sans"',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '34px',
                        letterSpacing: '-1px',
                    }}>
                        © 2025 All rights reserved
                    </div>
                </div>
            </footer>

        </div >
    )
}

export default Landing