import axios from 'axios';
const AskGPT = async (problemStatement, searchProp) => {
    const url = "https://datalyze.rag-api.machinehack.com/api/query";
    try {
        const response = await axios.post(
            `${url}`,
            {
                "query": `${problemStatement}  - ${searchProp}`,
                "mode": "generation"
            }
        );

        return response;
    } catch (err) {
        let errorMessage = "Consider experimenting with different questions.";
        throw new Error(errorMessage);

    }
}

export default AskGPT;