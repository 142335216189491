import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './VideoCarousel.css';

import { EffectCoverflow } from 'swiper/modules';

export default function VideoCarousel() {
    return (
        <div style={{ marginTop: "20px" }}>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                initialSlide={1}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow]}
                className="mySwiper"
            >
                <SwiperSlide style={{
                    pointerEvents: "none"
                }}>
                    <div style={{ display: "block", width: "100%" }}>
                        <h4 style={{
                            color: '#12B981',
                            textAlign: 'center',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '28px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            marginBottom: "10px"
                        }}>Data Loading</h4>
                        <iframe
                            src="https://player.vimeo.com/video/1057728634?autoplay=1&muted=1&loop=1&badge=0&autopause=0&player_id=0&app_id=58479"
                            frameBorder="0"
                            allow="autoplay"
                            style={{
                                width: "500px",
                                height: "350px",
                            }}
                            title="Datalyze Promo Video"
                        />
                    </div>
                </SwiperSlide>
                <SwiperSlide style={{
                    pointerEvents: "none"
                }}>
                    <div style={{ display: "block", width: "100%" }}>
                        <h4 style={{
                            color: '#12B981',
                            textAlign: 'center',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '28px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            marginBottom: "10px"
                        }}>Data Analysis</h4>
                        <iframe
                            src="https://player.vimeo.com/video/1057728658?autoplay=1&muted=1&loop=1&badge=0&autopause=0&player_id=0&app_id=58479"
                            frameBorder="0"
                            allow="autoplay"
                            style={{
                                width: "500px",
                                height: "350px",
                            }}
                            title="Datalyze Promo Video"
                        />
                    </div>

                </SwiperSlide>
                <SwiperSlide style={{
                    pointerEvents: "none"
                }}>
                    <div style={{ display: "block", width: "100%" }}>
                        <h4 style={{
                            color: '#12B981',
                            textAlign: 'center',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '28px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            marginBottom: "10px"
                        }}>Assessments</h4>
                        <iframe
                            src="https://player.vimeo.com/video/1057728680?autoplay=1&muted=1&loop=1&badge=0&autopause=0&player_id=0&app_id=58479"
                            frameBorder="0"
                            allow="autoplay"
                            style={{
                                width: "500px",
                                height: "350px",
                            }}
                            title="Datalyze Promo Video"
                        />
                    </div>
                </SwiperSlide>

            </Swiper>
        </div>
    );
}
