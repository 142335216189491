import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logoutUser } from "../slices";
const datalyzeEndpoint = process.env.REACT_APP_DATALYZE_ENDPOINT;

export const datalyzeProblemApis = createApi({
    reducerPath: "datalyzeProblemApis",
    baseQuery: fetchBaseQuery({
        baseUrl: datalyzeEndpoint,
    }),
    refetchOnFocus: true,
    endpoints: (builder) => ({
        getAllProblems: builder.query({
            query: (payload) => ({
                url: `problems?location=${payload?.location}`, // Adjust endpoint to match the API
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${payload.token}`, // Example of adding a JWT token
                    // Add additional headers here
                },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                queryFulfilled.catch((error) => {
                    if (error?.error?.status === 401) {
                        dispatch(logoutUser());
                    }
                });
            },
        }),
        getProblemById: builder.query({
            query: (payload) => ({
                url: `problems/${payload?.id}?location=${payload?.location}`, // Adjust endpoint to match the API
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${payload?.token}`, // Example of adding a JWT token
                    // Add additional headers here
                },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                queryFulfilled.catch((error) => {
                    if (error?.error?.status === 401) {
                        dispatch(logoutUser());
                    }
                });
            },
        }),
        getLeaderboard: builder.query({
            query: (payload) => ({
                url: `/leaderboard`, // Adjust endpoint to match the API
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${payload?.token}`, // Example of adding a JWT token
                    // Add additional headers here
                },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                queryFulfilled.catch((error) => {
                    if (error?.error?.status === 401) {
                        dispatch(logoutUser());
                    }
                });
            },
        }),
        sendEmail: builder.mutation({
            query: (payload) => ({
                url: `/contact-us`, // Adjust endpoint to match the API
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: payload
            }),
        }),
        updateCoins: builder.mutation({
            query: (payload) => ({
                url: "trackings",
                method: "PATCH",
                body: payload.body,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${payload.token}`, // Adding JWT token to headers
                },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                queryFulfilled.catch((error) => {
                    if (error?.error?.status === 401) {
                        dispatch(logoutUser());
                    }
                });
            },
        }),
    }),
});

export const {
    useGetAllProblemsQuery,
    useGetProblemByIdQuery,
    useUpdateCoinsMutation,
    useGetLeaderboardQuery,
    useSendEmailMutation
} = datalyzeProblemApis;