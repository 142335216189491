import React, { useState } from "react";
import {
  Box,
  Typography,
  LinearProgress,
  List,
  ListItem,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import Menu from "../assets/img/menu.svg";
import Profile from "../assets/img/Profile.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LogoutIcon from "./LogoutIcon";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../store/slices";
import { useDispatch } from "react-redux";

const Sidebar = ({
  userDetails,
  leaderboardData,
  data
}) => {
  const dispatch = useDispatch();
  const [isRankingsVisible, setRankingsVisible] = useState(false);
  const navigate = useNavigate();
  const handleToggleRankings = () => {
    setRankingsVisible((prev) => !prev);
  };

  let completedTaskCount = data?.filter((x) => x?.hasCompleted)?.length
  let totalTasks = data?.length || 0;
  let completionPercentage = totalTasks > 0 ? Math.round((completedTaskCount / totalTasks) * 100) : 0;

  return (
    <Box
      sx={{
        width: "323px",
        height: "100%",
        backgroundColor: "rgba(26, 29, 33, 0.4)",
        borderTop: "1px solid rgba(255, 255, 255, 0.08)",
        boxShadow:
          "16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset",
        color: "white",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        borderRadius: "16px",
      }}
    >
      <Box
        sx={{
          p: 2,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: 4, p: 2 }}
        >
          <div style={{ width: "fit-content", height: "auto", marginRight: "10px" }}>
            <img
              src={Profile}
              alt="Profile"
              style={{
                width: "52px",
                height: "auto",
              }}
            />
          </div>
          <Box>
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 1)",
                fontSize: "16px",
                fontWeight: 600,
                letterSpacing: "0.15px",
                minWidth: 100,
                maxWidth: 150,
              }}
            >
              {userDetails?.name}
            </Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: "4px",
                background: "rgba(249, 192, 3, 0.05)",
                textTransform: "none",
                color: "#F9C003",
                textAlign: "center",
                fontFamily: "Plus Jakarta Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "12px",
                letterSpacing: "0.15px",
                width: "100px",
                height: "28px",
                gap: "10px"
              }}
              onClick={() => {
                dispatch(logoutUser());
                navigate(`/login`);
              }}
            >
              Logout
              <LogoutIcon />
            </Button>
          </Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton>
              <img
                src={Menu}
                alt="Menu Icon"
                className="menu-image"
              />
            </IconButton>
          </div>
        </Box>

        <Box
          className="progress-box"
          sx={{
            borderRadius: "8px",
            borderTop:
              "1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08))",
            background:
              "linear-gradient(118deg, rgba(1, 255, 133, 0.16) -47.79%, rgba(204, 235, 235, 0.00) 100%)",
            height: "86px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            justifyContent: "space-evenly",
          }}
        >
          <Typography
            sx={{
              color: "var(--Noble-Black-400, #686B6E)",
              fontFamily: "Plus Jakarta Sans",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "18px",
              letterSpacing: "0.15px",
            }}
          >
            OVERALL PROGRESS
          </Typography>
          <Typography
            sx={{
              color: "#01FF85",
              fontFamily: "Plus Jakarta Sans",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
            }}
          >
            {completionPercentage}% completed
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "2px",
              background: "transparent",
            }}
          >
            {[1, 2, 3, 4, 5, 6]?.map((x, index) => {
              return (
                <LinearProgress
                  key={x}
                  variant="determinate"
                  value={index + 1 <= completedTaskCount ? 100 : 0}
                  sx={{
                    backgroundColor: "#333",
                    height: 2,
                    borderRadius: 5,
                    marginTop: 1,
                    width: "33%",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#4caf50", // Color of the filled portion (value)
                    },
                  }}
                />
              )
            })
            }
          </Box>
        </Box>

        <div className="try-now-card" style={{ marginBottom: "5px", marginTop: "15px" }}>
          <div>
            <p className="try-now-text">Try sample dataset</p>
          </div>
          <Button
            onClick={() => {
              navigate(`/gamified-data-analytics/custom`);
            }}
            variant="contained"
            className="try-now-btn" sx={{
              textTransform: "none",
              borderRadius: "12px",
              background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
              color: "#000",
              fontFamily: "Plus Jakarta Sans",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "18px",
              letterSpacing: " 0.15px",
            }}>
            Try Now
          </Button>
        </div>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          borderRadius: "12px",
          borderTop: "1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08))",
          background:
            "linear-gradient(118deg, rgba(10, 49, 49, 0.16) -47.79%, rgba(204, 235, 235, 0.00) 100%)",
          width: "100%",
          flexShrink: 0,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#FFF",
                fontFamily: "Plus Jakarta Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "18px",
                letterSpacing: "-0.5px",
              }}
            >
              YOUR &nbsp; RANK &nbsp;&nbsp;
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                fontFamily: "Plus Jakarta Sans",
                fontSize: "22px",
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: "24px",
                letterSpacing: "-1px",
              }}
            >
              {leaderboardData?.filter((x) => x?.userId === userDetails?._id)?.[0]?.rank}
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={handleToggleRankings}
            sx={{
              borderRadius: "4px",
              background: "rgba(1, 255, 133, 0.06)",
              color: "#00FF85",
              fontSize: "14px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "8px",
            }}
            endIcon={
              isRankingsVisible ? (
                <KeyboardArrowUpIcon sx={{ color: "#00FF85" }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: "#00FF85" }} />
              )
            }
          >
            View Rankings
          </Button>
        </Box>

        {isRankingsVisible && (
          <List sx={{ height: "200px", overflow: "auto", mt: 2 }}>
            {leaderboardData?.map((rank) => (
              <ListItem key={rank.id} sx={{ padding: 0, marginBottom: 1 }}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* For the rank ID */}
                    <Typography variant="body1" color="white">
                      {rank?.rank}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      alignItems="center"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                      }}
                    >
                      <Grid item sx={{ marginRight: 2 }}>
                        {" "}
                      </Grid>
                      <Grid item>
                        {" "}
                        <Typography variant="body1" color="white">
                          {rank?.userData?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2" color="gray" sx={{ textAlign: "right" }}>
                      {`${rank?.score}`}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        )}
      </Box>

      {/* Coins Section */}
      {/* <Box
        sx={{
          borderTop: "1px solid #333",
          paddingTop: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <MonetizationOnOutlinedIcon
            fontSize="large"
            sx={{ color: "#FFD700", marginRight: 1 }}
          />
          <Typography variant="body1">0</Typography>
        </Box>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#1DB954",
            color: "white",
            textTransform: "none",
            fontSize: "14px",
          }}
        >
          Redeem
        </Button>
      </Box> */}
    </Box>
  );
};

export default Sidebar;
