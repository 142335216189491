import React from "react";
import "./ScrollSnap.css";

const ScrollSnap = ({ targetUsers }) => {
    return (
        <>
            <div style={{ width: "100%", height: "400px", }}>
                <div className="support-scrollsnap"></div>
                <div className="scroll-container">
                    {targetUsers?.map((item, i) => {
                        return (
                            <div className="scroll-area">
                                <div
                                    className='infinite-scroll-item'
                                    key={i}
                                >
                                    <h4 className="scroll-item-header">{item.title}</h4>
                                    <p className="scroll-item-description">{item.description}</p>

                                    <img src={item?.image} alt={item?.title} style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "154px",
                                        bottom: 0,
                                        left: 0,
                                        padding: "0px 15px 15px 15px",
                                        borderRadius: "10px"
                                    }}></img>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
};

export default ScrollSnap;