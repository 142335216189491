import React, { useState, useEffect } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import DataTable from './DataTable';
import Delete from '../assets/img/delete.png';
import DataMapping from "../components/RawGraph/DataMapping"
import starIcon from "../assets/img/star.svg"
import trophyIcon from "../assets/img/trophy.png"
import TryAgainIcon from "../assets/img/try-again.svg";
import { LinearProgress } from "@mui/material";
import { motion } from "framer-motion";
import './DataAnalysis.css';
import { useUpdateCoinsMutation } from "../store/services/datalyzeProblems";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

const DataAnalysis = ({
  task,
  setTask,
  setShowQuiz,
  tableData,
  setTableData,
  showDeleteToast,
  selectedAction,
  setSelectedAction,
  handleCleanupData,
  showActionCreator,
  showQuiz,
  quizAnswers,
  setQuizAnswers,
  showChartSelector,
  setShowChartSelector,
  setShowAnalysis,
  charts,
  currentChart,
  dataMappingRef,
  dataSet,
  visualOptions,
  setVisualOptions,
  setRawViz,
  handleChartChange,
  setMessages,
  messages,
  chatExpanded,
  assessments,
  problemData,
  isCustomProblem,
  handleProceedActionCreator,
  handleProceedCompleteDataAnalysis,
  handleGoBackToAnalysis,
  handleSuccessfullyCreditedCoins,
}) => {
  const navigate = useNavigate();
  const { problemId } = useParams();

  const jwt = useSelector((state) => state?.user?.jwt);
  const [updateCoins] = useUpdateCoinsMutation();
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const currentQuestion = assessments?.[activeQuestionIndex] || {};
  const [attempted, setAttempted] = useState(!currentQuestion?.reattemptAllowed ? true : false);
  const [completion, setCompletion] = useState(false);
  const [showError, setShowError] = useState(false);
  const [hasSelectedRightAnswer, setHasSelectedRightAnswer] = useState(!currentQuestion?.reattemptAllowed ? currentQuestion?.correctOptionId : "");
  const [hasSelectedWrongAnswer, setHasSelectedWrongAnswer] = useState("");

  useEffect(() => {
    if (currentQuestion) {
      setQuizAnswers(!currentQuestion.reattemptAllowed ? currentQuestion.correctOptionId : "");
      setHasSelectedRightAnswer(!currentQuestion.reattemptAllowed ? currentQuestion.correctOptionId : "");
      setAttempted(!currentQuestion.reattemptAllowed);
    }
  }, [currentQuestion]);

  const columns = React.useMemo(() => {
    const dataTypeKeys = dataSet?.dataTypes ? Object.keys(dataSet.dataTypes) : [];

    const columnDefinitions = dataTypeKeys.map((column) => ({
      Header: column,
      accessor: column,
      type: dataSet?.dataTypes[column] === "string" ? "string" : dataSet?.dataTypes[column]?.type,
    }));

    const actionsColumn = {
      Header: 'Actions',
      Cell: ({ row }) => (
        <div>
          <button onClick={() => handleDeleteRow(row.index)}>
            <img src={Delete} alt="Delete" />
          </button>
        </div>
      ),
    };

    return showActionCreator ? [...columnDefinitions, actionsColumn] : [...columnDefinitions]
  }, [dataSet?.dataTypes, showActionCreator]);


  const handleAddRow = (value) => {
    setTableData([value, ...tableData]);
  };

  const handleEditRow = (index, value) => {
    const newData = [...tableData];
    newData[index] = value;
    setTableData(newData);
  };

  const handleDeleteRow = (index) => {
    const newData = [...tableData];
    newData.splice(index, 1);
    setTableData(newData);
  };

  const tableInstance = useTable({ columns, data: tableData }, useFilters, useSortBy, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of rows, use page
    prepareRow,
    setFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const handleAttemptAssessmentQuestion = async () => {
    const { data } = await updateCoins({
      body: {
        "problemId": problemId,
        "action": "assessment",
        "assessmentData": {
          "assessmentId": currentQuestion?._id,
          "optionId": quizAnswers?._id
        },
      },
      token: jwt
    });

    if (
      data?.data?.assessmentResponse?.attemptedCorrectly // If the user's first attempt is right
      && data?.data?.assessmentResponse?.reattemptAllowed
    ) {
      handleSuccessfullyCreditedCoins(data?.data?.totalScore);
      setHasSelectedRightAnswer(data?.data?.assessmentResponse?.correctOptionId)
      setQuizAnswers(data?.data?.assessmentResponse?.correctOptionId)
      setAttempted(true);
    } else if (  // If the user's second attempt is also wrong
      !data?.data?.assessmentResponse?.attemptedCorrectly
      && !data?.data?.assessmentResponse?.reattemptAllowed
    ) {
      setHasSelectedWrongAnswer(quizAnswers?._id)
      setHasSelectedRightAnswer(data?.data?.assessmentResponse?.correctOptionId);
      setQuizAnswers(data?.data?.assessmentResponse?.correctOptionId)
      setAttempted(true);
    } else if ( // If the user's second attempt is right
      data?.data?.assessmentResponse?.attemptedCorrectly
      && !data?.data?.assessmentResponse?.reattemptAllowed
    ) {
      handleSuccessfullyCreditedCoins(data?.data?.totalScore);
      setShowError(false);
      setHasSelectedRightAnswer(data?.data?.assessmentResponse?.correctOptionId)// Show the correct answer
      setAttempted(true);
      setQuizAnswers(data?.data?.assessmentResponse?.correctOptionId)
    } else {
      setQuizAnswers("")
      setHasSelectedWrongAnswer(quizAnswers?._id)
      setShowError(true);
      setTimeout(() => {
        setShowError(false)
      }, 2000)
    }
  }


  const handleAssessmentCompletion = async () => {
    setProgress(100);
    setCompletion(true);
    updateCoins({
      body: {
        "problemId": problemId,
        "action": "assessment",
        "hasCompleted": true
      },
      token: jwt
    });
  }

  return (
    <>
      <div className="data-analysis">
        {(task === "linked" || task === "cleanup") && (
          <div style={{ display: "flex", flexDirection: "column", height: "auto", }}>
            <DataTable
              handleAddRow={handleAddRow}
              setFilter={setFilter}
              getTableProps={getTableProps}
              headerGroups={headerGroups}
              getTableBodyProps={getTableBodyProps}
              page={page}
              prepareRow={prepareRow}
              handleEditRow={handleEditRow}
              handleDeleteRow={handleDeleteRow}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageOptions={pageOptions}
              pageSize={pageSize}
              setPageSize={setPageSize}
              data={dataSet?.dataset}
              dataSet={dataSet}
              showDeleteToast={showDeleteToast}
              selectedAction={selectedAction}
              setSelectedAction={setSelectedAction}
              handleCleanupData={handleCleanupData}
              showActionCreator={showActionCreator}
            />
            <button className="quiz-proceed-btn"
              style={{ margin: "0 auto", marginTop: "25px", marginBottom: "25px" }}
              onClick={() => {
                handleProceedActionCreator();
              }}
            >
              Proceed
            </button>
          </div>
        )}


        {task === "analyse" && !showQuiz && showChartSelector ?
          <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "start" }}>
            <div style={{ maxWidth: "420px", height: "270px", gap: "16px", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "start", marginRight: "20px", overflow: "auto" }}>
              {charts?.map((item) => {
                return (
                  <div style={{
                    width: "200px",
                    height: "56px",
                    background: currentChart?.metadata?.name === item?.metadata?.name ? "#010C12" : "rgba(54, 58, 61, 0.20)",
                    border: currentChart?.metadata?.name === item?.metadata?.name ? "0.5px solid #00FF85" : "0.5px solid #363A3D",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                    paddingLeft: "15px"
                  }}
                    onClick={() => {
                      handleChartChange(item);
                    }}
                  >
                    <p style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "24px",
                      color: "#fff"
                    }}>{item?.metadata?.name}</p>
                  </div>)
              })}
            </div>
            <div style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              height: "423px",
              borderRadius: "10px",
              backgroundColor: "#FFF",
              padding: "15px",
            }}>
              <img src={currentChart?.metadata?.thumbnail} alt={currentChart?.metadata?.name} style={{ marginBottom: "40px", maxWidth: "200px" }} />
              <h4 style={{
                fontSize: "14px",
                lineHeight: "24px",
                color: "#000",
                fontWeight: 500,
                marginBottom: "10px"
              }}>
                {currentChart?.metadata?.name}
              </h4>
              <p style={{
                fontSize: "12px",
                lineHeight: "16px",
                color: "#8E8C91",
                fontWeight: 500
              }}>{currentChart?.metadata?.description}</p>
              <button
                style={{
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: 700,
                  lineHeight: "18px",
                  width: "104px",
                  height: "34px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                  border: "none",
                  background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                  marginTop: "20px"
                }}
                onClick={() => {
                  setShowChartSelector(false);
                  setTimeout(() => {
                    setShowAnalysis(true);
                  }, 500)
                }}
              >
                Proceed Now
              </button>
            </div>
          </div>
          :
          task === "analyse" && !showQuiz && !showChartSelector &&
          <div style={{ display: "flex", flexDirection: "column", height: "auto" }}>
            <DataMapping
              ref={dataMappingRef}
              dimensions={currentChart.dimensions}
              dataTypes={dataSet.dataTypes}
              visualOptions={visualOptions}
              setVisualOptions={setVisualOptions}
              setRawViz={setRawViz}
              currentChart={currentChart}
              dataSet={dataSet}
              setMessages={setMessages}
              messages={messages}
              chatExpanded={chatExpanded}
              charts={charts}
              problemData={problemData}
              isCustomProblem={isCustomProblem}
              handleProceedCompleteDataAnalysis={handleProceedCompleteDataAnalysis}
            />
          </div >

        }
        {
          showQuiz &&
          <div>
            <div className="quiz-header" >
              <div className="quiz-header-container">
                <div className="quiz-progress-holder">
                  <p className="quiz-progress-text">{activeQuestionIndex + 1} out of 5 </p>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      width: "142px",
                      backgroundColor: "#383A3E",
                      height: "2px",
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#01fe87',  // Color of the progress bar
                      },
                    }} />
                </div>
                <div className="mcq-badge">
                  Multiple choice Question
                </div>
                <div className="coins-details-container">
                  <img src={starIcon} style={{
                    width: "20px",
                    height: "20px"
                  }} />
                  <p className="point-instruction-text">Points to  <br />be earned</p>
                  <p className="point-count-text">10</p>
                </div>
              </div>
            </div>

            {completion ? <div className="completion-container">
              <div className="completion-card">
                <img src={trophyIcon} alt="trophyIcon" style={{ width: "85px", height: "85px" }} />
                <h4 className="completion-header-text">Congratulations!</h4>
                <p className="completion-subheader-text"> You have completed successfully</p>
                <div style={{ display: "flex", flexDirection: "row", marginTop: "30px" }}>
                  <button className="choose-another-btn" onClick={() => {
                    navigate('/gamelist');
                    setCompletion(false);
                    setActiveQuestionIndex(0)
                    setProgress(0)

                  }}>Go back to menu</button>
                  <button
                    onClick={() => {
                      setTask("analyse");
                      setShowQuiz(false);
                      setCompletion(false);
                      setShowChartSelector(true)
                      setActiveQuestionIndex(0)
                      setProgress(0)
                    }}
                    className="stay-on-same-btn"
                  >Go to analysis
                  </button>
                </div>
              </div>
            </div> :
              <>
                <div className="analysis-header" style={{ marginTop: "30px", }}>
                  <h4 className="analysis-header-title">{currentQuestion?.question}</h4>
                  <div className="analysis-column-field-container" style={{ marginTop: "15px" }}>
                    {currentQuestion?.options?.map((option) => {
                      return (
                        <div className={`analysis-column-field-item ${hasSelectedRightAnswer === option._id ? "rightAnswer" : hasSelectedWrongAnswer === option._id ? "wrongAnswer" : ""}`}>
                          {hasSelectedRightAnswer === option._id && <div className="correct-answer-badge">CORRECT ANSWER</div>}
                          <label className="radio-container">
                            <input
                              disabled={hasSelectedWrongAnswer === option._id}
                              type="radio"
                              checked={option?.option === quizAnswers?.option}
                              onChange={(e) => {
                                if (e?.target?.checked) {
                                  setQuizAnswers(option);
                                }

                              }} />
                            <span className={"custom-radio"}
                            ></span>
                            <span className="checkbox-label">{option?.option}</span>
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>


                <div className="quiz-footer-container">
                  {showError &&
                    <motion.div
                      className="try-again-toast"
                      initial={{ y: 100, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ type: "spring", stiffness: 50, damping: 15 }}
                    >
                      <img src={TryAgainIcon} alt={TryAgainIcon} />
                      Try Again
                    </motion.div>
                  }
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <button className="go-back-btn" onClick={() => {
                      handleGoBackToAnalysis();
                    }}>
                      Go back
                    </button>
                    <button
                      disabled={!quizAnswers ? true : false}
                      className={quizAnswers ? "quiz-proceed-btn" : "go-back-btn"} onClick={() => {
                        if (attempted) {
                          setAttempted(false);
                          if (activeQuestionIndex === 4) {
                            handleAssessmentCompletion()
                          } else {
                            setCompletion(false);
                          }
                          if (activeQuestionIndex < 4) {
                            setActiveQuestionIndex((prev) => prev + 1);
                            setProgress((prev) => prev + 20)
                            setHasSelectedRightAnswer("");
                            setHasSelectedWrongAnswer("")

                          }
                        } else {
                          handleAttemptAssessmentQuestion();
                        }
                      }}>
                      {attempted ? "Next" : "Submit"}
                    </button>
                  </div>
                </div>
              </>
            }
          </div>
        }

      </div >





    </>
  );
};

export default DataAnalysis;



