import React from 'react'
import AIM_logo from "../../assets/img/aim_branding.svg"
import DatalyzeLogo from "../../assets/img/dataLyze-new.svg"
import Intsagram_grey from "../../assets/img/ig_grey.svg"
import Linkedin_grey from "../../assets/img/linkedin_grey.svg"
import X_grey from "../../assets/img/x_grey.svg"
import { useNavigate } from 'react-router-dom'

const Terms = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", padding: '20px', margin: '0 auto', color: '#fff', background: "#010C12", gap: "20px", textAlign: "justify" }}>
                <h1>Terms and Conditions</h1>
                <p>PLEASE NOTE:YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</p>

                <h2>TERMS OF USE</h2>
                <p>Welcome to Datalyze. Please read on to learn the rules and restrictions that govern your use of our website(s), products, services and applications (the “Services”). If you have any questions, comments, or concerns regarding these terms or the Services, please contact us.

                    These Terms of Use (the “Terms”) are a binding contract between you and Analytics India Magazine Pvt Ltd. (“MachineHack,” “we” and “us”). You must agree to and accept all of the Terms, or you don’t have the right to use the Services. Your use of the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use the Services. These Terms include the provisions in this document, as well as those in the Privacy Policy. In these Terms, the words “include” or “including” mean “including but not limited to”, and examples are for illustration purposes and are not limiting.

                </p>

                <h3 style={{ textTransform: "uppercase" }}>1. Will these Terms ever change?</h3>
                <p>We are constantly trying to improve our Services, so these Terms may need to change along with the Services. We reserve the right to change the Terms at any time, but if we do, we will bring it to your attention by placing a notice on the www.Datalyze.com website, by sending you an email, or by some other means. Changes will not apply retroactively and will become effective no sooner than 14 days after they are posted. However, changes addressing new functions for a Service or changes made for legal reasons will be effective immediately. If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes.

                    Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.</p>

                <h3 style={{ textTransform: "uppercase" }}>2. What about my privacy?</h3>
                <p>Datalyze takes the privacy of its users very seriously. For the current Datalyze Privacy Policy, please click <a href="/privacy-policy" style={{ color: '#00E5FF' }}>here</a>.</p>

                <h3 style={{ textTransform: "uppercase" }}>3. What are the basics of using Datalyze?</h3>
                <p>You may be required to sign up for an account, and select a password and username (“MachineHack User ID”). You promise to provide us with accurate, complete, and updated registration information about yourself. You may not select as your Datalyze User ID a name that you don’t have the right to use, or another person’s name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission. In the spirit of fairness, you may not have or control more than one active Datalyze User ID and participate in Competitions (defined below). If we determine, in our sole discretion, that you are operating under more than one Datalyze User ID, we may disqualify you from any Competition without notice and revoke access to your Datalyze User ID.

                    You represent and warrant that you are of legal age to form a binding contract. If you’re agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are authorized to agree to these Terms on that organization or entity’s behalf and bind them to these Terms (in which case, the references to “you” and “your” in these Terms, except for in this sentence, refer to that organization or entity), but you are still required to sign up each individual user from your organization with a Datalyze User ID. You are not allowed to allow multiple individuals to operate under one Datalyze User ID, even if you are an organization or entity.

                    You will only use the Services for your own internal, personal, non-commercial use , and not on behalf of or for the benefit of any third party, and only in a manner that complies with all laws that apply to you. If your use of the Services is prohibited by applicable laws, then you aren’t authorized to use the Services. We are not responsible if you use the Services in a way that breaks the law.

                    You will not share your account or password with anyone, and you must protect the security of your account and your password. You’re responsible for any activity associated with your account.</p>

                <h3 style={{ textTransform: "uppercase" }}>4. Your use of the Services is subject to the following additional restrictions:</h3>
                <p>1. You represent, warrant, and agree that you will not contribute any Content or User Submission (each of those terms is defined below) or otherwise use the Services or interact with the Services in a manner that:</p>
                <p>2. Infringes or violates the intellectual property rights or any other rights of anyone else (including Datalyze);</p>
                <p>3. Violates any law or regulation, including any applicable export control laws;</p>
                <p>4. Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</p>
                <p>5. Jeopardizes the security of your Datalyze account or anyone else’s (such as allowing someone else to log in to the Services as you);</p>
                <p>6. Attempts, in any manner, to obtain the password, account, or other security information from any other user;

                </p>
                <p>7. Violates the security of any computer network, or cracks any passwords or security encryption codes;

                </p>
                <p>8. Runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services’ infrastructure);</p>
                <p>9. “Crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content (through use of manual or automated means);

                </p>
                <p>10. Copies or stores any significant portion of the Content;</p>
                <p>11. Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services. A violation of any of the foregoing is grounds for termination of your right to use or access the Services.

                </p>
                <h3>5. What are my rights in Datalyze?</h3>
                <p>The materials displayed or performed or available on or through the Services...</p>

                <h3>6. Who is responsible for what I see and do on the Services?</h3>
                <p>Any information or content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such content originated...</p>

                <h3>7. Competitions on Datalyze</h3>
                <p>Competitions are subject to separate Competition Rules...</p>

                <h3>8. Do I have to grant any licenses to Datalyze or to other users?</h3>
                <p>Anything you post, upload, share, store, or otherwise provide through the Services is your &quot;User Submission&quot;...</p>

                <h3>9. Will Datalyze ever change the Services?</h3>
                <p>We may suspend or discontinue any part of the Services...</p>

                <h3>10. Does Datalyze cost anything?</h3>
                <p>The Datalyze Services may be free or we may charge a fee for using the Services...</p>

                <h3>11. What if I want to stop using Datalyze?</h3>
                <p>You’re free to stop using the Service at any time...</p>

                <h3 style={{ textTransform: "uppercase" }}>5. What are my rights in Datalyze?</h3>

                <p>The materials displayed or performed or available on or through the Services, including text, graphics, data, articles, photos, images, illustrations, and User Submissions, (collectively, the “Content”) are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including MachineHack’s) rights.

                    You understand that Datalyze owns the Services. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services.

                    The Services may allow you to copy or download certain Content; please remember that just because this functionality exists, doesn’t mean that all the restrictions above don’t apply — they do!</p>

                <h3 style={{ textTransform: "uppercase" }}>6. Who is responsible for what I see and do on the Services?</h3>
                <p>Any information or content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such content originated, and you access all such information and content at your own risk, and we aren’t liable for any errors or omissions in that information or content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you release us from all liability for you having acquired or not acquired Content through the Services. We can’t guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services.

                    You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it. You will keep all your registration information accurate and current. You are responsible for all your activity in connection with the Services.

                    The Services may contain links, information or connections to third party websites or services that are not owned or controlled by Datalyze (including job postings). When you access third party websites or engage with third party services, you accept that there are risks in doing so, and that Datalyze is not responsible for such risks. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third party website or service that you visit or utilize.

                    Datalyze has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third party websites or by any third party that you interact with through the Services. In addition, Datalyze will not and cannot monitor, verify, censor or edit the content of any third party site or service. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third party website or service.

                    Your interactions with organizations and/or individuals found on or through the Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that Datalyze will not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.

                    If there is a dispute between participants on this site, or between users and any third party, you agree that Datalyze is under no obligation to become involved. If you have a dispute with one or more other users, you release Datalyze, its officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services.

                </p>
                <h3 style={{ textTransform: "uppercase" }}>7. Competitions on Datalyze</h3>
                <p>Subject to these Terms, a user may post (“Host User”) a skill-based competition or challenge on the Services (“Competition”) for other users to participate in such Competition (“Participant User”). Competitions are subject to separate Competition Rules (defined below) that are established by the Host User.
                </p>
                <p>
                    Competitions are run according to rules for participation, the data set(s) to be used by the Participant Users (“Datasets”), the criteria used by the Host User to select a winner of the Competition (the “Metric”), the prize awarded to such winner and when such prize will be awarded, and such rules and selection criteria must comply with all applicable laws and these Terms (collectively, “Competition Rules”). Such Competition Rules will also include how and when a Participant User must submit Competition Entries (defined below) and the rights the Host User will be granted in such Competition Entry upon selecting such Competition Entry as the winner (“Winning Entry”) but the Host User will be granted rights in the Competition Entry only if the winner accepts payment of the prize. Certain rights granted in the Competition Entries and Winning Entries are described in Section 8 below. The Competition Rules may impose additional restrictions or requirements for Competitions.
                </p>
                <p>The Host User must conspicuously display the Metric within the Competition Rules. The Host User must select an objective Metric and must apply that Metric impartially to each Team’s (defined below) selected entries. In selecting a winner, the Host User must apply the Metric and select the Participant Users with the best rankings based on the Metric.
                </p>
                <p>The Host User and each Participant User will comply with all Competition Rules. The Host User (including any user from Host User’s organization) may not compete in its hosted Competition nor cancel a Competition without first contacting us and receiving our consent to such cancellation. Any participation by a Host User (or any user from Host User’s organization) in its hosted Competitions is done so on an ineligible basis (e.g. they cannot receive a prize) and will not otherwise affect the selection of the Winning Entry.</p>
                <p>Subject to the Competition Rules, Participant Users may collaborate as a team as long as each such Participant User confirms its team membership through the Service and does not participate on more than one team for a specific Competition (“Team”). To be clear, you may not participate on more than one team per Competition. Subject to the Competition Rules, Teams may, however, merge with other Teams, but the merged Team will be responsible for all past entries by the component Teams. If your Team wins a Competition, the prize for winning will be distributed equally amongst the members of the winning Team, unless we receive identical requests for unequal prize splits from every member of the Team.</p>
                <p>You may be a Host User or a Participant User for a specific Competition (but not both!). A Competition creates a direct relationship between a Host User and a Participant User, and Datalyze will have no liability for any actions or content of a Host User or a Participant User. Datalyze may provide a Host User with a template for the Competition Rules, but such template is provided without any warranty whatsoever and the Host User is solely responsible for its Competition Rules. It is the sole responsibility of the Host User to ensure that the Competition Rules comply with applicable law.</p>
                <p>You acknowledge and agree that Datalyze may, at any time in its sole discretion and without any liability but without any obligation to do so, remove or disqualify a Participant User, a Host User or a Competition if Datalyze believes that such Participant User, Competition or Host User are in violation these Terms or otherwise pose a risk to Datalyze, the Service or another user of the Service.</p>
                <p>Regardless of anything to the contrary, Participant Users acknowledge and agree that Datalyze and Host Users have no obligation to hold a Competition Entry in confidence or otherwise restrict their activities based on receipt of such Competition Entry. Datalyze has no obligation to become involved in disputes between users (for example, between a Participant User and a Host User) or between users and any third party relating the use of the Services. Datalyze does not oversee Competitions (including the selection of Winning Entries) and does not endorse any content users submit to the Services. When you host or participate in a Competition, you release Datalyze from claims, damages, and demands of every kind — known or unknown, suspected or unsuspected, disclosed or undisclosed — arising out of or in any way related to such disputes and the Services. All content you access or submit via the Services is at your own risk. You are solely responsible for any resulting damage or loss to any party.</p>

                <h3 style={{ textTransform: "uppercase" }}>10. Will Datalyze ever change the Services?</h3>
                <p>We’re always trying to improve the Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the Services. We’ll try to give you notice when we make a material change to the Services that would adversely affect you, but this isn’t always practical. Similarly, we reserve the right to remove any Content from the Services at any time, for any reason (including if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and without notice.</p>

                <h3 style={{ textTransform: "uppercase" }}>11. Does Datalyze cost anything?</h3>
                <p>The Datalyze Services may be free or we may charge a fee for using the Services. If you are using a free version of the Services, we will notify you before any Services you are then using begin carrying a fee, and if you wish to continue using such Services, you must pay all applicable fees for such Services.</p>

                <h3 style={{ textTransform: "uppercase" }}>13. What if I want to stop using Datalyze?</h3>

                <p>If you do not have any active Competitions that you are hosting, then you’re free to stop using the Service at any time; please refer to our Privacy Policy, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services. If you are hosting active Competitions then you should complete the Competitions, in full, before you stop using the Services.

                    Datalyze is also free to terminate (or suspend access to) your use of the Services or your account, for any reason in our discretion, including your breach of these Terms. Datalyze has the sole right to decide whether you are in violation of any of the restrictions in these Terms.

                    Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.

                    Provisions that, by their nature, should survive termination of these Terms will survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us.

                </p>

                <h3 style={{ textTransform: "uppercase" }}>14. What else do I need to know?</h3>
                <p>Warranty Disclaimer. Neither Datalyze nor its licensors or suppliers makes any representations or warranties concerning any content contained in or accessed through the Services (including Competitions), and we will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services. We (and our licensors and suppliers) make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through the Services. Products and services purchased or offered (whether or not following such recommendations and suggestions) through the Services are provided “AS IS” and without any warranty of any kind from Datalyze or others (unless, with respect to such others only, provided expressly and unambiguously in writing by a designated third party for a specific product). THE SERVICES AND CONTENT ARE PROVIDED BY DATALYZE(AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.

                    Limitation of Liability. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING TORT, CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE) WILL DATALYZE(OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) Rs100 OR (II) THE AMOUNTS PAID BY YOU TO DATALYZEIN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM, OR (C) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.

                    Indemnity. To the fullest extent allowed by applicable law, you will defend, indemnify and hold Datalyze, its affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any third party claims (including from other users) relating to (a) your submissions to the Services including any Content, User Submissions or Competitions, (b) your use of the Services (including any actions taken by a third party using your account), and (c) your violation of these Terms.

                    Assignment. You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without MachineHack’s prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.</p>
                <h3 style={{ textTransform: "uppercase" }}>About these Terms</h3>
                <p>These terms control the relationship between Datalyze and you. They do not create any third party beneficiary rights.

                    If you do not comply with these terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as taking action in the future).

                    If it turns out that a particular term is not enforceable, this will not affect any other terms.

                    The laws of Bengaluru, India, will apply to any disputes arising out of or relating to these terms or the Services. All claims arising out of or relating to these terms or the Services will be litigated exclusively in the state courts of Bengaluru, India.</p>
            </div>
            <footer style={{
                width: '100%',
                height: "400px",
                background: "#040404",
                position: "relative",
                display: "flex",
                flexDirection: 'column',
                padding: "80px 150px 50px 120px"
            }}>

                <div style={{
                    display: "flex",
                    flexDirection: 'row',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                    }}>
                        <img
                            src={DatalyzeLogo}
                        />
                        <img
                            onClick={() => {
                                window.open("https://analyticsindiamag.com/", "_blank")
                            }}
                            src={AIM_logo}
                            style={{
                                width: "120px",
                                alignSelf: "end",
                                marginTop: "5px",
                                cursor: "pointer"
                            }}
                        />
                    </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        marginLeft: "150px"
                    }}>
                        <p style={{
                            color: '#5C5C5E',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            textTransform: 'uppercase',
                            marginBottom: "22px"
                        }}>Explore</p>

                        <p
                            onClick={() => {
                                window.open("https://machinehack.com/", "_blank")
                            }}
                            style={{
                                color: 'rgba(255, 255, 255, 0.6)',
                                fontFamily: '"Plus Jakarta Sans"',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '34px',
                                letterSpacing: '-1px',
                                cursor: "pointer"
                            }}>About MachineHack
                        </p>
                        <p onClick={() => {
                            window.open("https://machinehack.com/", "_blank")
                        }} style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>About Datalyze</p>
                        <p style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Problem Statements</p>
                    </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        marginLeft: "150px"
                    }}>
                        <p style={{
                            color: '#5C5C5E',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            textTransform: 'uppercase',
                            marginBottom: "22px"
                        }}>Legal</p>

                        <a onClick={() => {
                            navigate("/terms")
                        }} style={{
                            background: "black",
                            border: "none",
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Terms of Service</a>
                        <a onClick={() => {
                            navigate("/privacy-policy")
                        }} style={{
                            background: "black",
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer",
                        }}>Privacy Policy</a>
                        <a onClick={() => {
                            navigate("/cancellation")
                        }} style={{
                            color: 'rgba(255, 255, 255, 0.6)',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '34px',
                            letterSpacing: '-1px',
                            cursor: "pointer"

                        }}>Cancellation & Refund Policy</a>
                    </div>


                    <button
                        onClick={() => {
                            navigate("/contact-us")
                        }}
                        style={{
                            display: 'flex',
                            width: '218.565px',
                            height: '49.783px',
                            padding: '17.391px 34.783px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10.87px',
                            flexShrink: 0,
                            borderRadius: '50px',
                            background: 'linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)',
                            color: '#000',
                            textAlign: 'center',
                            fontFamily: '"Plus Jakarta Sans"',
                            fontSize: '15.217px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '100%',
                            border: "none",
                            position: "absolute",
                            right: "100px",
                            top: "180px",
                            cursor: "pointer"
                        }}>
                        Contact Us
                    </button>
                </div>
                <div style={{
                    width: "100%",
                    height: "1px",
                    background: "#1B1B1B",
                    paddingLeft: "120px",
                    paddingRight: "80px",
                    marginTop: "50px",
                }}></div>
                <div style={{ width: "100%", height: "50px" }}></div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                }}>
                    <p style={{
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontFamily: '"Plus Jakarta Sans"',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '34px',
                        letterSpacing: '-1px',
                    }}>© Copyright Kruxonomy Consulting Pvt Ltd</p>


                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <img
                            onClick={() => {
                                window.open("https://www.instagram.com/machinehack/", "_blank")
                            }}
                            src={Intsagram_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                        <img
                            onClick={() => {
                                window.open("https://www.linkedin.com/company/machine-hack/", "_blank")
                            }}
                            src={Linkedin_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                        <img
                            onClick={() => {
                                window.open("https://x.com/JoinMachinehack", "_blank")
                            }}
                            src={X_grey}
                            style={{
                                width: "40px",
                                marginRight: "25px",
                                cursor: "pointer"
                            }} />
                    </div>

                    <div style={{
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontFamily: '"Plus Jakarta Sans"',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '34px',
                        letterSpacing: '-1px',
                    }}>
                        © 2025 All rights reserved
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Terms;
