import React, { useState } from "react";
import { Modal, Box, TextField, Button, Typography, CircularProgress } from "@mui/material";

const EmailModal = ({ open, handleClose, handleSendEmail, isLoading }) => {
    const [emailData, setEmailData] = useState({
        sender: "",
        subject: "",
        body: "",
    });

    const handleChange = (e) => {
        setEmailData({ ...emailData, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        handleSendEmail(emailData);
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="email-modal">
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "#000",
                    boxShadow: "16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset",
                    p: 4,
                    border: "0.4px solid #fff",
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" mb={2} sx={{ color: "#fff" }}>
                    Send an Email
                </Typography>

                <TextField
                    fullWidth
                    label="Your Email"
                    name="sender"
                    value={emailData.email}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                        style: { color: "#fff" }, // Label color (white)
                    }}
                    InputProps={{
                        style: { color: "#fff" }, // Text being entered color (white)
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#fff" }, // Default border color
                            "&:hover fieldset": { borderColor: "#fff" }, // Hover state
                            "&.Mui-focused fieldset": { borderColor: "#fff" }, // Focused state
                        },
                    }}
                />

                <TextField
                    fullWidth
                    label="Subject"
                    name="subject"
                    value={emailData.subject}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                        style: { color: "#fff" },
                    }}
                    InputProps={{
                        style: { color: "#fff" },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#fff" },
                            "&:hover fieldset": { borderColor: "#fff" },
                            "&.Mui-focused fieldset": { borderColor: "#fff" },
                        },
                    }}
                />

                <TextField
                    fullWidth
                    label="Message"
                    name="body"
                    value={emailData.body}
                    onChange={handleChange}
                    margin="normal"
                    multiline
                    rows={4}
                    InputLabelProps={{
                        style: { color: "#fff" },
                    }}
                    InputProps={{
                        style: { color: "#fff" },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#fff" },
                            "&:hover fieldset": { borderColor: "#fff" },
                            "&.Mui-focused fieldset": { borderColor: "#fff" },
                        },
                    }}
                />

                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button onClick={handleClose} sx={{ mr: 1, color: "grey" }}>
                        Cancel
                    </Button>
                    <Button

                        variant="contained"
                        sx={{ backgroundColor: "#01FF85", color: "black" }}
                        onClick={handleSubmit}
                        is
                    >
                        {isLoading ? <CircularProgress size={20} /> : "Send"}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EmailModal;