import React, { useRef } from "react";
import "./TextScrollEffect.css";

const TextScrollEffect = ({ text }) => {
    const sectionRef = useRef(null);
    return (
        <div
            className="reaveal-on-scroll-container"
            style={{ height: "100%", width: "100%" }}
            ref={sectionRef}
        >
            <h1 style={{ color: "#fff" }}>
                <span className="reveal-text">
                    {text}
                </span>
            </h1>
        </div>
    );
};

export default TextScrollEffect;
